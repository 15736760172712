import React from 'react'
import { useEffect, useState } from 'react';
import styles from './testimonialNew.module.css'
const TestimonialNew = () => {

    const [testimonialsData, setTestimonialsData] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);



    useEffect(() => {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/testimonials`; // Replace with your actual API endpoint

        // Fetch API to get data
        fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer your-token' // Add any necessary headers
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                setTestimonialsData(data.testimonials[0]);

            })
            .catch((error) => {
                console.error('Error occurred:', error);
            });
    }, []);

    const formattedDescription = testimonialsData?.description
        ? testimonialsData.description.replace(
            /\bRUNMYBOT\b/g,
            `<span class="rmb_run_p">RUN</span><span class="rmb_my_p">MY</span><span class="rmb_bot_p">BOT®</span>`
        )
        : ""; // Fallback to an empty string if description is not available
    const plainText = formattedDescription.replace(/<[^>]+>/g, "");
    const shouldTruncate = plainText.length > 1024;

    return (
        <div>
            <div className={styles.fullTitle}>
                {/* Container for text and image aligned in a row */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',     // Stack items vertically
                        justifyContent: 'space-between',  // Distribute space between elements

                    }}
                >        <div style={{ display: "flex", padding: "20px 40px 0px 40px" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="60" viewBox="0 0 32 60" fill="none" style={{ marginRight: "8px" }}>
                            <path d="M28.7583 33.3236V59.0205H0V38.7322C0 27.751 1.5863 19.7961 4.77061 14.8792C8.93832 8.32907 15.5469 3.36529 24.5848 0.00537109L31.1407 8.61004C25.6735 10.4949 21.6522 13.3046 19.0708 17.0332C16.4894 20.7619 15.0494 26.194 14.7509 33.3236H28.7583Z" fill="#7398C7" />

                        </svg><svg xmlns="http://www.w3.org/2000/svg" width="32" height="60" viewBox="0 0 32 60" fill="none">
                            <path d="M28.7583 33.3236V59.0205H0V38.7322C0 27.751 1.5863 19.7961 4.77061 14.8792C8.93832 8.32907 15.5469 3.36529 24.5848 0.00537109L31.1407 8.61004C25.6735 10.4949 21.6522 13.3046 19.0708 17.0332C16.4894 20.7619 15.0494 26.194 14.7509 33.3236H28.7583Z" fill="#7398C7" />
                        </svg>
                    </div>


                    <div
                        className={`${styles.fullTitle_content} ${isExpanded ? "" : styles.shrinkedImage}`}
                        style={{ alignItems: isExpanded ? "flex-end" : "", }}
                    >


                        {formattedDescription ? (

                            <p
                                style={{ marginRight: "20px", flex: 1, paddingBottom: "48px" }}>
                                <span dangerouslySetInnerHTML={{
                                    __html: isExpanded || !shouldTruncate
                                        ? formattedDescription
                                        : formattedDescription.slice(0, 1024) + "...",
                                }}
                                ></span>
                                {shouldTruncate && !isExpanded && (
                                    <p
                                        onClick={() => setIsExpanded(true)}
                                        style={{
                                            fontWeight: 600,
                                            cursor: "pointer",
                                            display: "inline",
                                            marginLeft: "5px",
                                        }}
                                    >
                                        more
                                    </p>
                                )}
                            </p>


                        ) : (
                            <p
                                style={{ marginRight: "20px", flex: 1, paddingBottom: "48px" }}
                            >
                                No description available.
                            </p>
                        )}
                        <div className={styles.flex_container}>
                            <img
                                src={`${process.env.REACT_APP_API_BASE_URL}attachments/${testimonialsData.image}`}
                                alt="img"
                                className={styles.testmonial_image}

                            />
                        </div>
                    </div>

                    {/* Bottom box */}
                    <div
                        className={styles.testmonial_image_container}
                    >
                        {/* Left-side paragraph inside bottom box */}
                        <p className={styles.bottomname}
                            style={{
                                flex: 7,

                                textAlign: 'right', // Align text to the left
                                margin: 0,         // Remove default margin

                            }}
                        >
                            {testimonialsData.name}
                        </p>

                        {/* Right-side paragraph inside bottom box */}
                        <p className={styles.bottomDesignmation}
                            style={{
                                flex: 3,

                                textAlign: 'right', // Align text to the right
                                marginLeft: "10px",          // Remove default margin
                            }}
                        >
                            {testimonialsData.designation}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonialNew