import React ,{useState,useEffect} from 'react'
import runmybotlogo from '../../Assets/Images/Eventpro/RunMyBot.svg'
import eventpro from '../../Assets/Images/Eventpro/eventpro.svg'
import styles from './runmyboteventpro.module.css'
import PlatformFeatureCard from '../../components/Platformfeatures/PlatformFeatureCard'
import platformlayer from '../../Assets/Images/Eventpro/platform/platfromLayer.svg'
import platformevent from '../../Assets/Images/Eventpro/platform/platfromevent.svg'
import platformsds from '../../Assets/Images/Eventpro/platform/platformsds.svg'
import platformwds from '../../Assets/Images/Eventpro/platform/platfromwds.svg'
import eventprotriangle from '../../Assets/Images/Eventpro/eventprotriangle.svg'
import whychooseeventpro from '../../Assets/Images/Eventpro/whychooseeventpro.svg'
import eventpro_challenges from '../../Assets/Images/Eventpro/eventpro_challenges.svg'
import ImageMarquee from '../../components/ImageMarquee/ImageMarquee'
import { fetchEndusers } from '../../apiServices/EndUsers/apiCalls'
import CustomScrollbar from '../../components/CustomScrollbar/CustomScrollbar'
const featuresData=[
    {
      
        description: "We create purpose-built integrations designed specifically to address unique business needs and objectives. By developing tailored solutions that seamlessly connect your systems, applications, and data, we ensure that every integration enhances efficiency, drives innovation, and supports your strategic goals. Our approach goes beyond generic solutions, focusing on delivering precise, targeted integrations that fit perfectly within your existing ecosystem and adapt to your evolving requirements.",
        image: eventpro_challenges,
      },
]
const featuresData2=[
    {
      
        description: "We create purpose-built integrations designed specifically to address unique business needs and objectives. By developing tailored solutions that seamlessly connect your systems, applications, and data, we ensure that every integration enhances efficiency, drives innovation, and supports your strategic goals. Our approach goes beyond generic solutions, focusing on delivering precise, targeted integrations that fit perfectly within your existing ecosystem and adapt to your evolving requirements.",
        image: whychooseeventpro,
      },
]
const cardData = [
    {
      label: "Application Layer",
      backgroundColor:"#F6FAFF",
      image: platformlayer,
      imageBackgroundColor: "rgba(233, 239, 247, 1)",
      descriptions: [
        {
          label: "Seamless Integration",
          labelColor: "#4F63BE" ,
          text: "Acts as middleware between our platform and external applications, translating communications into actionable events."
        },
        {
          label: "Business Logic Management",
          labelColor: "#4F63BE" ,
          text: "Handles additional business logic needed for smooth integration with other applications."
        }
      ]
    },
    {
      label: "Event Stream",
      backgroundColor:"#F6F7FF",
      image: platformevent,
      imageBackgroundColor: "rgba(236, 237, 250, 1)",
      descriptions: [
        {
          label: "Continuous Data Flow",
          labelColor: "#8E95EA" ,
          text: "Powered by leading event streaming platforms like Apache Kafka, our system ensures a continuous stream of data points."
        },
        {
          label: "Standardized Events",
          labelColor: "#8E95EA" ,
          text: " Events are represented in a standardized format compatible with our platform, ensuring seamless communication and response."
        }
      ]
    },
    {
      label: "Workflow Definition Service (WDS)",
      backgroundColor:"#FFF7FF",
      image: platformsds,
      imageBackgroundColor: "rgba(255, 237, 255, 1)",
      descriptions: [
        {
          label: "Comprehensive Workflow Management",
          labelColor: "#E27DDD" ,
          text: "Create, modify, and manage workflows with ease using our robust API services."
        },
        {
          label: "Action Triggers",
          labelColor: "#E27DDD" ,
          text: "Actions such as creation, modification, or deletion of workflows trigger corresponding events, which are available for further processing by other system components."
        }
      ]
    },
    {
      label: "Schedule Definition Service (SDS)",
      backgroundColor:" #F4FEFF",
      image: platformwds,
      imageBackgroundColor: "rgba(223, 246, 249, 1)",
      descriptions: [
        {
          label: "User-Specific Scheduling",
          labelColor: "#171717" ,
          text: "Define and manage user-specific schedules and holiday calendars to fine-tune workflow execution."
        },
        {
          label: "Event Publication",
          labelColor: "#171717" ,
          text: "SDS publishes events based on schedules, allowing the clock module to synchronize and trigger workflows accurately."
        }
      ]
    }
  ];

const RunmybotEventpropage = () => {
  const [imageData,setImagedata]=useState([])

  useEffect(() => {
    // Fetch image data from API
    const fetchImages = async () => {
      try {
        const result = await fetchEndusers('api/end-users'); // Adjust endpoint
        // Extract logo URLs from API response
        const images = result.end_users.map((user) => `${process.env.REACT_APP_API_BASE_URL}attachments/${user.logo}`);
        setImagedata(images)       
      } catch (err) {
   
      } 
    };

    fetchImages();
  }, []);
  return (
    <div className={styles.outerDiv}>
      <div className={styles.eventproSection1}>

        <div className={styles.headingSection} >

          <div className={styles.imageContainer}>
            <img src={runmybotlogo} alt="Image 1" className={styles.image} />
            <img src={eventpro} alt="Image 2" className={styles.image} />
          </div>
          <p>Managing workflows across multiple applications and platforms has never been easier. Our event-driven Advanced Scheduler addresses key challenges in business process automation and management with innovative solutions that streamline integration, enhance flexibility, and drive efficiency. 
          </p>
          <div className={styles.say_Hello}>
          Say Hello to &nbsp;<span className={styles.run}>RUN</span><span className={styles.my}>MY</span><span className={styles.bot}>BOT</span>&nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="39" height="33" viewBox="0 0 39 33" fill="none">
              <g clip-path="url(#clip0_1_19778)">
                <path d="M0 13.8179H29.9634C34.9501 13.8179 39 17.8578 39 22.8322V33H0V13.8179Z" fill="black" />
                <path d="M6.57673 0V1.44987H1.61675V4.62465H5.89684V6.07452H1.61675V9.82871H6.78552V11.2786H0V0H6.57673Z" fill="black" />
                <path d="M7.46265 3.2229H9.11151L11.5179 8.92894L13.9243 3.2229H15.5731L12.1657 11.2813H10.8728L7.46532 3.2229H7.46265Z" fill="black" />
                <path d="M20.2922 3.06271C20.8731 3.06271 21.4138 3.16685 21.9144 3.37779C22.4149 3.58873 22.8459 3.8771 23.2072 4.24825C23.5686 4.61939 23.847 5.06263 24.0397 5.57797C24.2431 6.08262 24.3475 6.64067 24.3475 7.2548V7.83422H19.2912V6.54455H22.7656C22.6371 5.91173 22.356 5.40174 21.9251 5.01457C21.4834 4.6274 20.94 4.43515 20.2922 4.43515C19.9041 4.43515 19.5561 4.50458 19.251 4.64342C18.9432 4.78227 18.6835 4.97719 18.4667 5.22284C18.2392 5.47116 18.0679 5.75953 17.9501 6.0933C17.8323 6.42706 17.7734 6.79287 17.7734 7.18805C17.7734 7.60726 17.843 7.98908 17.9822 8.33353C18.1107 8.68865 18.3008 8.99305 18.547 9.25205C18.796 9.50037 19.085 9.69796 19.4196 9.84749C19.7516 9.98633 20.1183 10.0558 20.5171 10.0558C20.8517 10.0558 21.1461 10.0104 21.4058 9.91958C21.6654 9.8288 21.8849 9.72466 22.0669 9.60451C22.2596 9.48702 22.4176 9.36152 22.5354 9.23336C22.6638 9.10519 22.7602 9.00106 22.8271 8.92629L23.9594 9.74869C23.689 10.0825 23.4214 10.3548 23.151 10.5711C22.8807 10.7874 22.6076 10.9583 22.3266 11.0864C22.0455 11.2146 21.7564 11.3054 21.454 11.3614C21.1408 11.4148 20.8089 11.4415 20.4529 11.4415C19.8506 11.4415 19.2885 11.3374 18.7719 11.1265C18.2553 10.9182 17.8082 10.6245 17.4308 10.248C17.0534 9.87152 16.7563 9.42561 16.5421 8.91027C16.3253 8.39494 16.2183 7.83155 16.2183 7.21742C16.2183 6.60329 16.32 6.06393 16.5261 5.5566C16.7295 5.05195 17.0106 4.61138 17.3666 4.2349C17.7333 3.86909 18.1642 3.58072 18.6594 3.36444C19.1546 3.16151 19.698 3.05737 20.2922 3.05737V3.06271Z" fill="black" />
                <path d="M27.3349 3.22295L27.5785 4.36843C27.8462 4.02399 28.1915 3.72226 28.6117 3.45792C29.032 3.19358 29.5486 3.06274 30.1615 3.06274C30.5604 3.06274 30.9324 3.13217 31.2777 3.27101C31.6337 3.40986 31.9335 3.6208 32.1825 3.89849C32.4421 4.16817 32.6402 4.50461 32.7794 4.91314C32.9293 5.33235 33.0042 5.8103 33.0042 6.34699V11.2787H31.4865V6.34699C31.4865 5.07868 30.9217 4.44587 29.7895 4.44587H29.5138C29.2327 4.46723 28.9757 4.54733 28.7375 4.68885C28.4993 4.81701 28.2959 4.97455 28.1245 5.15612C27.9532 5.33769 27.8167 5.53794 27.7204 5.75155C27.624 5.96783 27.5758 6.16542 27.5758 6.34699V11.2787H26.0581V3.22295H27.3349Z" fill="black" />
                <path d="M37.319 0.726318V3.22555H39V4.5793H37.319V8.70463C37.319 8.99568 37.3457 9.22531 37.3993 9.39886C37.4528 9.56975 37.5331 9.70593 37.6429 9.80205C37.7499 9.8875 37.8838 9.9409 38.047 9.96226C38.2077 9.9943 38.3977 10.0103 38.6118 10.0103C38.7832 10.0103 38.9143 10.005 39 9.9943V11.3961C38.9678 11.4068 38.9036 11.4175 38.8072 11.4282C38.7216 11.4388 38.5985 11.4442 38.4352 11.4442C38.0685 11.4442 37.7312 11.4015 37.418 11.316C37.0941 11.2199 36.8131 11.065 36.5695 10.8567C36.3259 10.6485 36.1385 10.3708 36.0047 10.0263C35.8709 9.6819 35.8013 9.25201 35.8013 8.73668V4.5793H34.5405V3.22555H35.8013V0.726318H37.319Z" fill="black" />
                <path d="M2.89623 17.532H8.23364C8.74489 17.532 9.2267 17.6281 9.68175 17.8204C10.1368 18.0126 10.5383 18.2769 10.8809 18.6134C11.2262 18.9498 11.4939 19.345 11.6866 19.7962C11.8793 20.2502 11.9757 20.7308 11.9757 21.2381C11.9757 21.7454 11.8793 22.2447 11.6866 22.6907C11.4939 23.1366 11.2262 23.5317 10.8809 23.8735C10.5356 24.2153 10.1368 24.485 9.68443 24.6772C9.22938 24.8695 8.74757 24.9656 8.23899 24.9656H5.30797V29.2485H2.89355V17.532H2.89623ZM5.31332 22.8642H8.23096C8.60035 22.8642 8.9162 22.7093 9.17585 22.4023C9.43549 22.0925 9.56665 21.7054 9.56665 21.2381C9.56665 20.8002 9.43549 20.4184 9.17585 20.0953C8.9162 19.7722 8.60035 19.612 8.23096 19.612H5.31332V22.8642Z" fill="white" />
                <path d="M21.0715 29.2458L18.3252 24.9629H16.3658V29.2458H13.9514V17.5293H19.3102C19.8215 17.5293 20.3033 17.6254 20.7583 17.8177C21.2134 18.0099 21.6095 18.2743 21.9468 18.6107C22.2841 18.9471 22.5518 19.3423 22.7445 19.7936C22.9372 20.2475 23.0336 20.7281 23.0336 21.2354C23.0336 21.9644 22.8516 22.6185 22.4875 23.2033C22.1235 23.7881 21.6363 24.2366 21.0314 24.5517L24.024 29.2458H21.0715ZM16.3658 22.8642H19.3022C19.6582 22.8642 19.966 22.7093 20.2257 22.4023C20.4853 22.0925 20.6165 21.7054 20.6165 21.2381C20.6165 20.8002 20.4853 20.4184 20.2257 20.0953C19.966 19.7722 19.6582 19.612 19.3022 19.612H16.3658V22.8642Z" fill="white" />
                <path d="M29.9956 17.3452C30.8361 17.3452 31.6337 17.5028 32.3832 17.8178C33.1354 18.1329 33.7858 18.5655 34.3372 19.1155C34.8887 19.6655 35.3276 20.309 35.6515 21.0513C35.9754 21.7936 36.1387 22.5813 36.1387 23.4197C36.1387 24.2582 35.9754 25.0432 35.6515 25.7775C35.3276 26.5117 34.8913 27.1526 34.3372 27.7026C33.7858 28.2527 33.1354 28.6852 32.3832 29.0003C31.6311 29.3154 30.8361 29.4729 29.9956 29.4729C29.1551 29.4729 28.3414 29.3154 27.5972 29.0003C26.8531 28.6852 26.2027 28.2527 25.6432 27.7026C25.0838 27.1526 24.6448 26.5117 24.3209 25.7775C23.997 25.0432 23.8364 24.2582 23.8364 23.4197C23.8364 22.5813 23.997 21.7936 24.3209 21.0513C24.6448 20.309 25.0865 19.6655 25.6432 19.1155C26.2027 18.5655 26.8531 18.1356 27.5972 17.8178C28.3414 17.5028 29.1417 17.3452 29.9956 17.3452ZM29.9849 19.6522C29.4629 19.6522 28.9704 19.751 28.51 19.9513C28.0496 20.1515 27.6454 20.4185 27.3028 20.755C26.9575 21.0914 26.6871 21.4892 26.4864 21.9485C26.2856 22.4078 26.1866 22.8991 26.1866 23.4197C26.1866 23.9404 26.2856 24.4077 26.4864 24.8616C26.6871 25.3155 26.9575 25.7107 27.3028 26.0445C27.6454 26.3809 28.0496 26.6479 28.51 26.8482C28.9704 27.0484 29.4629 27.1472 29.9849 27.1472C30.5068 27.1472 30.9779 27.0484 31.441 26.8482C31.9014 26.6506 32.3003 26.3809 32.6375 26.0445C32.9748 25.708 33.2425 25.3129 33.4432 24.8616C33.644 24.4077 33.743 23.9271 33.743 23.4197C33.743 22.9124 33.644 22.4078 33.4432 21.9485C33.2425 21.4892 32.9748 21.0914 32.6375 20.755C32.3003 20.4185 31.9014 20.1515 31.441 19.9513C30.9806 19.751 30.4961 19.6522 29.9849 19.6522Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_1_19778">
                  <rect width="39" height="33" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

      </div>
      <div className={styles.eventproSection5} style={{ maxWidth: "100%" }}>
        <ImageMarquee images={imageData} speed={20} />
      </div>
      <div className={styles.eventproSection2}>
        <p className={styles.heading}>Key Challenges Addressed</p>
        <div className={styles.featuresContainer}>
          {featuresData.map((feature, index) => (
            <div
              key={index}
              className={`${styles.featureSection} ${index % 2 === 0 ? styles.rightImage : styles.leftImage
                }`}
            >
              <div className={styles.textContent}>
                 <CustomScrollbar>
                 <p className={styles.featureDescription}>{feature.description}</p>
                 </CustomScrollbar>
               
              </div>
              <div className={styles.imageContainer2}>
                <img src={feature.image} alt={feature.label} className={styles.featureImage} />
              </div>
            </div>
          ))}
        </div>

        <p className={styles.headingFeatures}>Platform Features</p>
        <PlatformFeatureCard data={cardData} />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" ,marginTop:"70px",marginBottom:"70px"}}>
          <img src={eventprotriangle} className={styles.eventprotriangle} alt='Run my bot' />
        </div>
        <p className={styles.heading}>Why Choose Event Pro?</p>
        <div className={styles.featuresContainer}>
          {featuresData2.map((feature, index) => (
            <div
              key={index}
              className={`${styles.featureSection} ${index % 2 === 0 ? styles.rightImage : styles.leftImage
                }`}
            >
              <div className={styles.textContent}>
                <CustomScrollbar>
                <p className={styles.featureDescription}>{feature.description}</p>
                </CustomScrollbar>

                
              </div>
              <div className={styles.imageContainer2}>
                <img src={feature.image} alt={feature.label} className={styles.featureImage} />
              </div>
            </div>
          ))}
        </div>

      </div>



    </div>
  )
}

export default RunmybotEventpropage