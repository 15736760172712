import React from 'react'
import styles from './contactUspage.module.css'

const ContactDetail = () => {


    return (
        <div className={styles.footerBottom}>
            <div className={styles.usAddress} >
                <p className={styles.states}> United States</p>


                <br />
                <p className={styles.addressParagraph}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "10px" }}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <g clip-path="url(#clip0_2231_356)">
                                <path d="M9.24693 5.34258C9.24693 4.10358 8.23893 3.09558 6.99993 3.09558C5.76093 3.09558 4.75293 4.10358 4.75293 5.34258C4.75293 6.58158 5.76093 7.58958 6.99993 7.58958C8.23893 7.58958 9.24693 6.58158 9.24693 5.34258ZM6.99993 6.22239C6.51403 6.22239 6.12012 5.82848 6.12012 5.34258C6.12012 4.85668 6.51403 4.46277 6.99993 4.46277C7.48583 4.46277 7.87974 4.85668 7.87974 5.34258C7.87974 5.82848 7.48583 6.22239 6.99993 6.22239Z" fill="black" />
                                <path d="M6.99974 14.0002C6.99974 14.0002 12.3364 9.47375 12.3364 5.34266C12.3364 2.39531 9.94709 0.00598145 6.99974 0.00598145C4.05238 0.00598145 1.66309 2.39531 1.66309 5.34266C1.66309 9.42552 6.99974 14.0002 6.99974 14.0002ZM3.71083 7.78842C3.25925 6.87801 3.03027 6.05513 3.03027 5.34266C3.03027 4.28238 3.44316 3.28556 4.1929 2.53583C4.94264 1.78609 5.93946 1.3732 6.99974 1.3732C8.06002 1.3732 9.05684 1.78609 9.80657 2.53583C10.5563 3.28556 10.9692 4.28238 10.9692 5.34266C10.9692 6.06637 10.7401 6.89628 10.2883 7.80934C9.89484 8.60461 9.33801 9.45125 8.63336 10.3258C8.05215 11.0471 7.46477 11.6686 7.00365 12.1262C6.54138 11.6642 5.95171 11.0368 5.36833 10.3103C4.66259 9.4314 4.10491 8.58292 3.71083 7.78842Z" fill="black" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2231_356">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>

                            1441 Broadway, #5<br />
                            New York, NY 10018<br />
                        </span>
                    </div>






                    <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "10px" }}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2231_305)">
                                <path d="M13.2117 9.2287L11.3155 8.09712C10.3969 7.54816 9.20956 7.81574 8.61437 8.70532L8.06729 9.52186C6.50545 8.75412 5.24307 7.48892 4.4783 5.92518L5.29017 5.38463C6.18077 4.79259 6.45196 3.60682 5.90757 2.68628L4.78784 0.790666C4.43388 0.19795 3.74381 -0.105644 3.06787 0.0333633C2.02889 0.243052 1.12247 0.873128 0.564286 1.77482C0.013505 2.6543 -0.142611 3.72496 0.13362 4.72527C1.36741 9.16744 4.83932 12.6369 9.28089 13.8664C10.2798 14.1428 11.3491 13.9866 12.2273 13.4356C13.1286 12.8776 13.7591 11.9711 13.9684 10.9318C14.1017 10.261 13.7985 9.57874 13.2111 9.2287H13.2117ZM12.913 10.7221C12.7619 11.4676 12.309 12.1176 11.662 12.5171C11.0372 12.9092 10.2761 13.0206 9.56554 12.8237C5.49204 11.6955 2.30781 8.51313 1.1763 4.43918C0.978802 3.72732 1.0895 2.9653 1.48214 2.3396C1.88522 1.68832 2.54064 1.23394 3.29161 1.08517C3.3266 1.0781 3.36227 1.0744 3.39793 1.0744C3.58837 1.0744 3.76467 1.17538 3.86157 1.33929L4.98433 3.23423C5.23701 3.66168 5.11084 4.21266 4.69733 4.48731L3.50593 5.28063C3.29127 5.42334 3.20783 5.69832 3.30709 5.93628C4.20745 8.08231 5.91127 9.79079 8.0545 10.6969C8.29204 10.7951 8.56558 10.7127 8.70925 10.4993L9.50968 9.30443C9.78592 8.89044 10.338 8.7659 10.765 9.02137L12.662 10.1529C12.8578 10.2701 12.9584 10.4979 12.914 10.7218L12.913 10.7221Z" fill="black" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2231_305">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <a className={styles.iconWrap} href="tel:+16462906311">  +1 646 290 6311</a>

                    </div>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "10px", marginTop: "5px" }}>
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.4545 11.4091H2.54545C1.87058 11.4084 1.22355 11.14 0.746341 10.6628C0.269131 10.1856 0.000719279 9.53855 0 8.86367V3.1364C0.000719279 2.46152 0.269131 1.81449 0.746341 1.33728C1.22355 0.860074 1.87058 0.591662 2.54545 0.590942H11.4545C12.1294 0.591662 12.7764 0.860074 13.2537 1.33728C13.7309 1.81449 13.9993 2.46152 14 3.1364V8.86367C13.9993 9.53855 13.7309 10.1856 13.2537 10.6628C12.7764 11.14 12.1294 11.4084 11.4545 11.4091ZM2.54545 1.86367C2.20802 1.86406 1.88453 1.99827 1.64593 2.23687C1.40733 2.47547 1.27311 2.79897 1.27273 3.1364V8.86367C1.27311 9.2011 1.40733 9.5246 1.64593 9.7632C1.88453 10.0018 2.20802 10.136 2.54545 10.1364H11.4545C11.792 10.136 12.1155 10.0018 12.3541 9.7632C12.5927 9.5246 12.7269 9.2011 12.7273 8.86367V3.1364C12.7269 2.79897 12.5927 2.47547 12.3541 2.23687C12.1155 1.99827 11.792 1.86406 11.4545 1.86367H2.54545Z" fill="black" />
                            <path d="M7.00061 6.91228C6.29279 6.91254 5.60515 6.67643 5.04678 6.24142L0.882416 3.00243C0.749161 2.89876 0.662546 2.7464 0.641627 2.57887C0.620707 2.41134 0.667197 2.24236 0.770868 2.1091C0.874539 1.97584 1.0269 1.88923 1.19443 1.86831C1.36197 1.84739 1.53095 1.89388 1.6642 1.99755L5.82853 5.23654C6.16342 5.49766 6.57592 5.63947 7.00058 5.63947C7.42524 5.63947 7.83774 5.49766 8.17264 5.23654L12.337 1.99755C12.4702 1.89388 12.6392 1.84739 12.8067 1.86831C12.9743 1.88923 13.1266 1.97584 13.2303 2.1091C13.334 2.24236 13.3805 2.41134 13.3595 2.57887C13.3386 2.7464 13.252 2.89876 13.1187 3.00243L8.95438 6.24142C8.39596 6.67629 7.70839 6.91238 7.00061 6.91228Z" fill="black" />
                        </svg>

                        <a className={styles.iconWrap} href="mailto:hello@actionfi.com" target="_blank" rel="noopener noreferrer"> hello@actionfi.com </a>
                    </div>



                </p>
            </div>

            <div className={styles.indiaAdress}>
                <div className={styles.india}>
                    <p className={styles.states}>India</p>
                </div>

                <div className={styles.indiaAdressContainer}>
                    <div className={styles.calicutAdress}>

                        <p className={styles.addressParagraph}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "10px" }}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2231_356)">
                                        <path d="M9.24693 5.34258C9.24693 4.10358 8.23893 3.09558 6.99993 3.09558C5.76093 3.09558 4.75293 4.10358 4.75293 5.34258C4.75293 6.58158 5.76093 7.58958 6.99993 7.58958C8.23893 7.58958 9.24693 6.58158 9.24693 5.34258ZM6.99993 6.22239C6.51403 6.22239 6.12012 5.82848 6.12012 5.34258C6.12012 4.85668 6.51403 4.46277 6.99993 4.46277C7.48583 4.46277 7.87974 4.85668 7.87974 5.34258C7.87974 5.82848 7.48583 6.22239 6.99993 6.22239Z" fill="black" />
                                        <path d="M6.99974 14.0002C6.99974 14.0002 12.3364 9.47375 12.3364 5.34266C12.3364 2.39531 9.94709 0.00598145 6.99974 0.00598145C4.05238 0.00598145 1.66309 2.39531 1.66309 5.34266C1.66309 9.42552 6.99974 14.0002 6.99974 14.0002ZM3.71083 7.78842C3.25925 6.87801 3.03027 6.05513 3.03027 5.34266C3.03027 4.28238 3.44316 3.28556 4.1929 2.53583C4.94264 1.78609 5.93946 1.3732 6.99974 1.3732C8.06002 1.3732 9.05684 1.78609 9.80657 2.53583C10.5563 3.28556 10.9692 4.28238 10.9692 5.34266C10.9692 6.06637 10.7401 6.89628 10.2883 7.80934C9.89484 8.60461 9.33801 9.45125 8.63336 10.3258C8.05215 11.0471 7.46477 11.6686 7.00365 12.1262C6.54138 11.6642 5.95171 11.0368 5.36833 10.3103C4.66259 9.4314 4.10491 8.58292 3.71083 7.78842Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2231_356">
                                            <rect width="14" height="14" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span>
                                    C5 UL Cyber Park, Special Economic Zone,<br />Calicut, Kerala, India - 673016
                                    <br />
                                </span>
                            </div>


                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "10px" }}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2231_305)">
                                        <path d="M13.2117 9.2287L11.3155 8.09712C10.3969 7.54816 9.20956 7.81574 8.61437 8.70532L8.06729 9.52186C6.50545 8.75412 5.24307 7.48892 4.4783 5.92518L5.29017 5.38463C6.18077 4.79259 6.45196 3.60682 5.90757 2.68628L4.78784 0.790666C4.43388 0.19795 3.74381 -0.105644 3.06787 0.0333633C2.02889 0.243052 1.12247 0.873128 0.564286 1.77482C0.013505 2.6543 -0.142611 3.72496 0.13362 4.72527C1.36741 9.16744 4.83932 12.6369 9.28089 13.8664C10.2798 14.1428 11.3491 13.9866 12.2273 13.4356C13.1286 12.8776 13.7591 11.9711 13.9684 10.9318C14.1017 10.261 13.7985 9.57874 13.2111 9.2287H13.2117ZM12.913 10.7221C12.7619 11.4676 12.309 12.1176 11.662 12.5171C11.0372 12.9092 10.2761 13.0206 9.56554 12.8237C5.49204 11.6955 2.30781 8.51313 1.1763 4.43918C0.978802 3.72732 1.0895 2.9653 1.48214 2.3396C1.88522 1.68832 2.54064 1.23394 3.29161 1.08517C3.3266 1.0781 3.36227 1.0744 3.39793 1.0744C3.58837 1.0744 3.76467 1.17538 3.86157 1.33929L4.98433 3.23423C5.23701 3.66168 5.11084 4.21266 4.69733 4.48731L3.50593 5.28063C3.29127 5.42334 3.20783 5.69832 3.30709 5.93628C4.20745 8.08231 5.91127 9.79079 8.0545 10.6969C8.29204 10.7951 8.56558 10.7127 8.70925 10.4993L9.50968 9.30443C9.78592 8.89044 10.338 8.7659 10.765 9.02137L12.662 10.1529C12.8578 10.2701 12.9584 10.4979 12.914 10.7218L12.913 10.7221Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2231_305">
                                            <rect width="14" height="14" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <a className={styles.iconWrap} href="tel:+914952973774">+91 495 297 3774</a>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "10px", marginTop: "5px" }}>
                                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4545 11.4091H2.54545C1.87058 11.4084 1.22355 11.14 0.746341 10.6628C0.269131 10.1856 0.000719279 9.53855 0 8.86367V3.1364C0.000719279 2.46152 0.269131 1.81449 0.746341 1.33728C1.22355 0.860074 1.87058 0.591662 2.54545 0.590942H11.4545C12.1294 0.591662 12.7764 0.860074 13.2537 1.33728C13.7309 1.81449 13.9993 2.46152 14 3.1364V8.86367C13.9993 9.53855 13.7309 10.1856 13.2537 10.6628C12.7764 11.14 12.1294 11.4084 11.4545 11.4091ZM2.54545 1.86367C2.20802 1.86406 1.88453 1.99827 1.64593 2.23687C1.40733 2.47547 1.27311 2.79897 1.27273 3.1364V8.86367C1.27311 9.2011 1.40733 9.5246 1.64593 9.7632C1.88453 10.0018 2.20802 10.136 2.54545 10.1364H11.4545C11.792 10.136 12.1155 10.0018 12.3541 9.7632C12.5927 9.5246 12.7269 9.2011 12.7273 8.86367V3.1364C12.7269 2.79897 12.5927 2.47547 12.3541 2.23687C12.1155 1.99827 11.792 1.86406 11.4545 1.86367H2.54545Z" fill="black" />
                                    <path d="M7.00061 6.91228C6.29279 6.91254 5.60515 6.67643 5.04678 6.24142L0.882416 3.00243C0.749161 2.89876 0.662546 2.7464 0.641627 2.57887C0.620707 2.41134 0.667197 2.24236 0.770868 2.1091C0.874539 1.97584 1.0269 1.88923 1.19443 1.86831C1.36197 1.84739 1.53095 1.89388 1.6642 1.99755L5.82853 5.23654C6.16342 5.49766 6.57592 5.63947 7.00058 5.63947C7.42524 5.63947 7.83774 5.49766 8.17264 5.23654L12.337 1.99755C12.4702 1.89388 12.6392 1.84739 12.8067 1.86831C12.9743 1.88923 13.1266 1.97584 13.2303 2.1091C13.334 2.24236 13.3805 2.41134 13.3595 2.57887C13.3386 2.7464 13.252 2.89876 13.1187 3.00243L8.95438 6.24142C8.39596 6.67629 7.70839 6.91238 7.00061 6.91228Z" fill="black" />
                                </svg>

                                <a className={styles.iconWrap} href="mailto:hello@actionfi.com" target="_blank" rel="noopener noreferrer"> hello@actionfi.com </a>
                            </div>




                        </p>
                    </div>
                    <div className={styles.cochinAdress}>

                        <p className={styles.addressParagraph}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "10px" }}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2231_356)">
                                        <path d="M9.24693 5.34258C9.24693 4.10358 8.23893 3.09558 6.99993 3.09558C5.76093 3.09558 4.75293 4.10358 4.75293 5.34258C4.75293 6.58158 5.76093 7.58958 6.99993 7.58958C8.23893 7.58958 9.24693 6.58158 9.24693 5.34258ZM6.99993 6.22239C6.51403 6.22239 6.12012 5.82848 6.12012 5.34258C6.12012 4.85668 6.51403 4.46277 6.99993 4.46277C7.48583 4.46277 7.87974 4.85668 7.87974 5.34258C7.87974 5.82848 7.48583 6.22239 6.99993 6.22239Z" fill="black" />
                                        <path d="M6.99974 14.0002C6.99974 14.0002 12.3364 9.47375 12.3364 5.34266C12.3364 2.39531 9.94709 0.00598145 6.99974 0.00598145C4.05238 0.00598145 1.66309 2.39531 1.66309 5.34266C1.66309 9.42552 6.99974 14.0002 6.99974 14.0002ZM3.71083 7.78842C3.25925 6.87801 3.03027 6.05513 3.03027 5.34266C3.03027 4.28238 3.44316 3.28556 4.1929 2.53583C4.94264 1.78609 5.93946 1.3732 6.99974 1.3732C8.06002 1.3732 9.05684 1.78609 9.80657 2.53583C10.5563 3.28556 10.9692 4.28238 10.9692 5.34266C10.9692 6.06637 10.7401 6.89628 10.2883 7.80934C9.89484 8.60461 9.33801 9.45125 8.63336 10.3258C8.05215 11.0471 7.46477 11.6686 7.00365 12.1262C6.54138 11.6642 5.95171 11.0368 5.36833 10.3103C4.66259 9.4314 4.10491 8.58292 3.71083 7.78842Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2231_356">
                                            <rect width="14" height="14" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span>
                                    MEDA Building, Kakkanad,<br />Ernakulam, Kerala, India - 682030
                                    <br />
                                </span>

                            </div>

                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "10px" }}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2231_305)">
                                        <path d="M13.2117 9.2287L11.3155 8.09712C10.3969 7.54816 9.20956 7.81574 8.61437 8.70532L8.06729 9.52186C6.50545 8.75412 5.24307 7.48892 4.4783 5.92518L5.29017 5.38463C6.18077 4.79259 6.45196 3.60682 5.90757 2.68628L4.78784 0.790666C4.43388 0.19795 3.74381 -0.105644 3.06787 0.0333633C2.02889 0.243052 1.12247 0.873128 0.564286 1.77482C0.013505 2.6543 -0.142611 3.72496 0.13362 4.72527C1.36741 9.16744 4.83932 12.6369 9.28089 13.8664C10.2798 14.1428 11.3491 13.9866 12.2273 13.4356C13.1286 12.8776 13.7591 11.9711 13.9684 10.9318C14.1017 10.261 13.7985 9.57874 13.2111 9.2287H13.2117ZM12.913 10.7221C12.7619 11.4676 12.309 12.1176 11.662 12.5171C11.0372 12.9092 10.2761 13.0206 9.56554 12.8237C5.49204 11.6955 2.30781 8.51313 1.1763 4.43918C0.978802 3.72732 1.0895 2.9653 1.48214 2.3396C1.88522 1.68832 2.54064 1.23394 3.29161 1.08517C3.3266 1.0781 3.36227 1.0744 3.39793 1.0744C3.58837 1.0744 3.76467 1.17538 3.86157 1.33929L4.98433 3.23423C5.23701 3.66168 5.11084 4.21266 4.69733 4.48731L3.50593 5.28063C3.29127 5.42334 3.20783 5.69832 3.30709 5.93628C4.20745 8.08231 5.91127 9.79079 8.0545 10.6969C8.29204 10.7951 8.56558 10.7127 8.70925 10.4993L9.50968 9.30443C9.78592 8.89044 10.338 8.7659 10.765 9.02137L12.662 10.1529C12.8578 10.2701 12.9584 10.4979 12.914 10.7218L12.913 10.7221Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2231_305">
                                            <rect width="14" height="14" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <a className={styles.iconWrap} href="tel:+914842993399"> +91 484 299 3399</a>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "10px", marginTop: "5px" }}>
                                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4545 11.4091H2.54545C1.87058 11.4084 1.22355 11.14 0.746341 10.6628C0.269131 10.1856 0.000719279 9.53855 0 8.86367V3.1364C0.000719279 2.46152 0.269131 1.81449 0.746341 1.33728C1.22355 0.860074 1.87058 0.591662 2.54545 0.590942H11.4545C12.1294 0.591662 12.7764 0.860074 13.2537 1.33728C13.7309 1.81449 13.9993 2.46152 14 3.1364V8.86367C13.9993 9.53855 13.7309 10.1856 13.2537 10.6628C12.7764 11.14 12.1294 11.4084 11.4545 11.4091ZM2.54545 1.86367C2.20802 1.86406 1.88453 1.99827 1.64593 2.23687C1.40733 2.47547 1.27311 2.79897 1.27273 3.1364V8.86367C1.27311 9.2011 1.40733 9.5246 1.64593 9.7632C1.88453 10.0018 2.20802 10.136 2.54545 10.1364H11.4545C11.792 10.136 12.1155 10.0018 12.3541 9.7632C12.5927 9.5246 12.7269 9.2011 12.7273 8.86367V3.1364C12.7269 2.79897 12.5927 2.47547 12.3541 2.23687C12.1155 1.99827 11.792 1.86406 11.4545 1.86367H2.54545Z" fill="black" />
                                    <path d="M7.00061 6.91228C6.29279 6.91254 5.60515 6.67643 5.04678 6.24142L0.882416 3.00243C0.749161 2.89876 0.662546 2.7464 0.641627 2.57887C0.620707 2.41134 0.667197 2.24236 0.770868 2.1091C0.874539 1.97584 1.0269 1.88923 1.19443 1.86831C1.36197 1.84739 1.53095 1.89388 1.6642 1.99755L5.82853 5.23654C6.16342 5.49766 6.57592 5.63947 7.00058 5.63947C7.42524 5.63947 7.83774 5.49766 8.17264 5.23654L12.337 1.99755C12.4702 1.89388 12.6392 1.84739 12.8067 1.86831C12.9743 1.88923 13.1266 1.97584 13.2303 2.1091C13.334 2.24236 13.3805 2.41134 13.3595 2.57887C13.3386 2.7464 13.252 2.89876 13.1187 3.00243L8.95438 6.24142C8.39596 6.67629 7.70839 6.91238 7.00061 6.91228Z" fill="black" />
                                </svg>

                                <a className={styles.iconWrap} href="mailto:hello@actionfi.com" target="_blank" rel="noopener noreferrer"> hello@actionfi.com </a>
                            </div>




                        </p>

                    </div>
                </div>
                {/* <div className={styles.commonemail}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "10px", marginTop: "5px" }}>
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.4545 11.4091H2.54545C1.87058 11.4084 1.22355 11.14 0.746341 10.6628C0.269131 10.1856 0.000719279 9.53855 0 8.86367V3.1364C0.000719279 2.46152 0.269131 1.81449 0.746341 1.33728C1.22355 0.860074 1.87058 0.591662 2.54545 0.590942H11.4545C12.1294 0.591662 12.7764 0.860074 13.2537 1.33728C13.7309 1.81449 13.9993 2.46152 14 3.1364V8.86367C13.9993 9.53855 13.7309 10.1856 13.2537 10.6628C12.7764 11.14 12.1294 11.4084 11.4545 11.4091ZM2.54545 1.86367C2.20802 1.86406 1.88453 1.99827 1.64593 2.23687C1.40733 2.47547 1.27311 2.79897 1.27273 3.1364V8.86367C1.27311 9.2011 1.40733 9.5246 1.64593 9.7632C1.88453 10.0018 2.20802 10.136 2.54545 10.1364H11.4545C11.792 10.136 12.1155 10.0018 12.3541 9.7632C12.5927 9.5246 12.7269 9.2011 12.7273 8.86367V3.1364C12.7269 2.79897 12.5927 2.47547 12.3541 2.23687C12.1155 1.99827 11.792 1.86406 11.4545 1.86367H2.54545Z" fill="black" />
                            <path d="M7.00061 6.91228C6.29279 6.91254 5.60515 6.67643 5.04678 6.24142L0.882416 3.00243C0.749161 2.89876 0.662546 2.7464 0.641627 2.57887C0.620707 2.41134 0.667197 2.24236 0.770868 2.1091C0.874539 1.97584 1.0269 1.88923 1.19443 1.86831C1.36197 1.84739 1.53095 1.89388 1.6642 1.99755L5.82853 5.23654C6.16342 5.49766 6.57592 5.63947 7.00058 5.63947C7.42524 5.63947 7.83774 5.49766 8.17264 5.23654L12.337 1.99755C12.4702 1.89388 12.6392 1.84739 12.8067 1.86831C12.9743 1.88923 13.1266 1.97584 13.2303 2.1091C13.334 2.24236 13.3805 2.41134 13.3595 2.57887C13.3386 2.7464 13.252 2.89876 13.1187 3.00243L8.95438 6.24142C8.39596 6.67629 7.70839 6.91238 7.00061 6.91228Z" fill="black" />
                        </svg>

                        <a className={styles.iconWrap} href="mailto:hello@actionfi.com" target="_blank" rel="noopener noreferrer"> hello@actionfi.com </a>
                    </div> 
                </div> */}
            </div>

        </div>
    )
}

export default ContactDetail