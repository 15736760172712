import { useEffect } from "react";

const replaceText = (el) => {
  if (el.getAttribute("data-processed")) return;

  let content = el.innerHTML;

  const runMyBotRegex = /\bRUNMYBOT\b/g;
  const dataWranglerRegex = /\bData Wrangler\b/gi;

  content = content.replace(
    runMyBotRegex,
    `<span class="rmb_run_p">RUN</span><span class="rmb_my_p">MY</span><span class="rmb_bot_p">BOT®</span>`
  );

  content = content.replace(
    dataWranglerRegex,
    `<span style="font-family: 'AstonPoliz';">Data Wrangler</span>`
  );

  el.innerHTML = content;
  el.setAttribute("data-processed", "true");
};

const TextReplacer = () => {
  useEffect(() => {
    const processElements = () => {
      const elements = document.querySelectorAll("p:not([data-processed]), h3:not([data-processed]), h2:not([data-processed])");
      elements.forEach(replaceText);
    };

    // Initial processing
    processElements();

    // Observe DOM changes
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            if (node.matches("p, h3, h2, li")) {
              replaceText(node);
            } else {
              node.querySelectorAll("p, h3, h2, li").forEach(replaceText);
            }
          }
        });
      });
    });

    const observerTarget = document.body;
    observer.observe(observerTarget, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return null;
};

export default TextReplacer;
