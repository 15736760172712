import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ScrollProvider, } from './Utils/ScrollContext';
import './App.css';
import Layout from './components/Layout/Layout';
import HomePage from './pages/HomePage';
import SolutionPage from './pages/Solutions/SolutionPage';
import WhyActionfi from './pages/WhyActionfi/WhyActionfi';
import CompanyPage from './pages/Company/CompanyPage';
import PartnerPage from './pages/Partners/PartnerPage';
import AianalyzePage from './pages/Aianalyzepage/AianalyzePage';
import AutomationLibraryInner from './pages/Automationlibrary/AutomationLibraryInner';
import PlatformPage from './pages/Platform/PlatformPage';
import ConnectPage from './pages/Connect/ConnectPage';
import RunmybotsapPage from './pages/RunmybotforSAP/RunmybotsapPage';
import RunmybotEventpropage from './pages/RunmybotEventpro/RunmybotEventpropage';
import PartnerSignup from './pages/PartnerSignUp/PartnerSignup';
import PartnerSignIn from './pages/PartnerSignIn/PartnerSignIn';
import CareersPage from './pages/Careers/CareersPage';
import WorkflowsPage from './pages/ByWorkflows/WorkflowsPage';
import BysystemsPage from './pages/BySystems/BysystemsPage';
import IntegrationTile from './pages/Integration/IntegrationTile/IntegrationTile';
import ByFunctionPage from './pages/ByFunction/ByFunctionPage';
import ContactUspage from './pages/ContactUs/ContactUspage';
import ScheduleDemopage from './pages/ScheduleDemo/ScheduleDemopage';
import DataWranglerPage from './pages/Datawrangler/DataWranglerPage';
import EndorPage from './pages/Endor/EndorPage';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ScrollToTop from './components/ScrollToTop';
import TermsofUse from './pages/Termsofuse/TermsofUse';
import MovingBackground from './components/Animations/MovingBackground';
import AutomationLibraryPage from './pages/Automationlibrary/AutomationLibraryPage';
// import TestimonialSection from './components/Testimonials/TestimonialSection';
import TestimonialNew from './components/Testimonials/TestimonialNew';
import TextReplacer from './Utils/TextReplacer';
/*
purpose of this: 
Author: Jinto Jose
Completed date: 
*/

function App() {


  return (
    <Router>
      <ScrollToTop /> {/* Ensure this is included */}
      <MovingBackground />
      <TextReplacer />
      <ScrollProvider>
        <Routes>

          <Route path="/" element={<Layout />} >
            <Route index element={<HomePage />} />
            <Route path="solutions" element={<SolutionPage />} />
            <Route path="whyactionfi" element={<WhyActionfi />} />
            <Route path="company" element={<CompanyPage />} />
            <Route path="partners" element={<PartnerPage />} />
            <Route path="ai-analyze" element={<AianalyzePage />} />
            <Route path="automation-library/:route/:slug1/:slug2" element={<AutomationLibraryInner />} />
            <Route path="platform" element={<PlatformPage />} />
            <Route path="runmybotforsap" element={<RunmybotsapPage />} />
            <Route path="connect" element={<ConnectPage />} />
            <Route path="runmybot-eventpro" element={<RunmybotEventpropage />} />
            <Route path="partner/signup" element={<PartnerSignup />} />
            <Route path="partner/signin" element={<PartnerSignIn />} />
            <Route path="careers" element={<CareersPage />} />
            <Route path="workflows" element={<WorkflowsPage />} />
            <Route path="systems" element={<BysystemsPage />} />
            <Route path="integration/:id" element={<IntegrationTile />} />
            <Route path="functions/:slug?" element={<ByFunctionPage />} />
            <Route path="contactus" element={<ContactUspage />} />
            <Route path="scheduledemo" element={<ScheduleDemopage />} />
            <Route path="datawrangler" element={<DataWranglerPage />} />
            <Route path="workflowbuilder" element={<EndorPage />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="termsofuse" element={<TermsofUse />} />
            <Route path="testimonials" element={<TestimonialNew />} />
            <Route path="automationlibrary" element={<AutomationLibraryPage />} />
          </Route>
        </Routes>
      </ScrollProvider>

    </Router>

  );
}

export default App;
