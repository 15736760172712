import React from 'react'
import styles from './careersPage.module.css'
import { useState, useEffect } from 'react'
import CareerOpportunities from '../../components/CareersCard/CareerOppertunities'
import careers_header from '../../Assets/Images/Careers/careers_header.svg'


const CareersPage = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        // const response = await fetch("https://cms-v2.actionfi.com/api/careers");
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/careers`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this runs once on mount.
  if (isLoading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  if (error) {
    return <div className={styles.error}>Error: {error}</div>;
  }
  return (
    <div className={styles.careersContainer}>
      <div className={styles.contentContainer}>
        <h1 className={styles.heading}>Be Part of Our Mission</h1>
        <p className={styles.description}>
          We're looking for passionate people who desire to accept challenges,<br />build rapport with teams easily, and tell us what to do.
        </p>
        <img
          src={careers_header}
          alt="Connect Illustration"
          className={styles.image}
        />
        <h1 className={styles.heading2}>Career Opportunities </h1>

      </div>
      <div>
        <CareerOpportunities jobs={data} />
      </div>



    </div>
  )
}

export default CareersPage