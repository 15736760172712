/*
purpose of this: 
Author: Jinto Jose
Completed date: 
*/



import React from 'react'
import { useRef } from 'react';
import styles from './whyActionfi.module.scss'
import ActionfiFeatures from './ActionfiFeatures';
import CaseStudies from './CaseStudies';
import bannerVideo from '../../Assets/Videos/5706559_Coll_wavebreak_Animation_1280x720.mp4'
import byIntegartion from '../../Assets/Images/WhyActionfi/byIntegartion.svg';
import byfunction from '../../Assets/Images/WhyActionfi/byfunction.svg';
import byWorkflows from '../../Assets/Images/WhyActionfi/byWorkflows.svg';

const WhyActionfi = () => {
    const caseStudiesRef = useRef(null);
    const customerData = [
        {
            label: "We connect everything",
            content: "We integrate every part of your organization and beyond to dismantle barriers and enable rapid digital progress. This allows business and IT teams to collaborate more effectively and roll out new services faster.",
            image: byIntegartion,
        },
        {
            label: "We use multiple systems",
            content: "We have expertise with a multitude of ERP systems, spanning both horizontal and vertical solutions, as well as multiple generations of technology to maximize the value of every system. We support any environment, cloud, and ecosystem.",
            image: byfunction,
        },
        {
            label: "We adopt customizations",
            content: "At actionfi, we view requests for customizations as valuable opportunities rather than inconveniences. We understand each organization has unique needs, and we're committed to tailoring our solutions to fit those requirements. ",
            image: byWorkflows,
        },

    ];
    const scrollToCaseStudies = () => {
        caseStudiesRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    return (

        <div className={styles.whyActionfiMain}>
            <div className={styles.whyActionfi}>
                <video autoPlay loop muted playsInline>
                    <source src={bannerVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className={styles.casebuttonRow}>
                <div className={`${styles.bannerWidget} ${styles.automationLibrary}`}>
            <div className={styles.flexContainer}>
                <div className={styles.flexChild}>
                    <a  className={styles.cercleArrow}>
                        <div className={styles.chevronContainer}>
                            <div className={styles.chevron}></div>
                            <div className={styles.chevron}></div>
                            <div className={styles.chevron}></div>
                        </div>
                    </a>
                </div>
               
                <button className={styles.caseStudiesBtn} onClick={scrollToCaseStudies}>Case Studies</button>
          
                <div className={styles.flexChild}>
                    <a  className={styles.cercleArrow}>
                        <div className={styles.chevronContainer}>
                            <div className={styles.chevron}></div>
                            <div className={styles.chevron}></div>
                            <div className={styles.chevron}></div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
                  
                   
          
            
                </div>

                <div className={styles.headingSection} >
                    <span>actionfi helps organizations connect business data between anything & anywhere</span>
                    <p>actionfi's robust platform enables businesses to extract, enhance, elevate and interlink business data while creating bridges between applications, systems, processes, and people. And that's just one of the many things we do.
                    </p>

                </div>

            </div>
            <div className={styles.whyAfi_section2}>
                <span>Why customers choose actionfi ?</span>
                <div className={styles.CustomerGroup}>
                    {customerData.map((item, index) => (
                        <div key={index} className={styles.Container}>
                            <div className={styles.imageWrapper}>

                                {item.image && <img src={item.image} alt={item.label} />}
                            </div>
                            <h3>{item.label}</h3>
                            <p>{item.content}</p>
                        </div>
                    ))}
                </div>

            </div>

            <div className={styles.whyAfi_section3}>
                <ActionfiFeatures />
                <div ref={caseStudiesRef}>
                    <CaseStudies />
                </div>

            </div>
        </div>

    )
}

export default WhyActionfi