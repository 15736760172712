import React from 'react'
import { useState } from 'react';
import scheduledemoimg from '../../Assets/Images/ScheduleAdemo/scheduleademo.svg';
import styles from './scheduleDemo.module.css'

const ScheduleDemopage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    country: "",
    companyWebsite: "",
    companyEmail: "",
  });
  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState(''); // Holds the success or error message
  const [showMessage, setShowMessage] = useState(false); // Controls visibility of the message box

  const handleChange = (e) => {
    const { id, value } = e.target;
  
    // Update form data
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  
    // Clear error for the specific field if input is valid
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
  
      // Validate input dynamically
      if (id === "companyWebsite" && /^(https?:\/\/)?[\w.-]+\.[a-z]{2,}$/.test(value)) {
        delete newErrors.companyWebsite;
      } else if (id === "companyEmail" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        delete newErrors.companyEmail;
      } else if (value.trim()) {
        delete newErrors[id];
      }
  
      return newErrors;
    });
  };
  
  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) newErrors.firstName = "First name is required";



    if (!formData.companyEmail.trim()) {
      newErrors.companyEmail = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.companyEmail)) {
      newErrors.companyEmail = "Enter a valid email address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Form is valid if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      company_name: formData.companyName,
      country: formData.country,
      company_website: formData.companyWebsite,
      company_email: [formData.companyEmail],
    };

    try {
      // const response = await fetch("https://cms-v2.actionfi.com/api/demo-request", {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/demo-request`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        setResponseMessage('Message sent successfully!');
        setShowMessage(true);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          companyName: '',
          message: '',
        });
        

      } else {
        setResponseMessage('Failed to send the message. Please try again.');
        setShowMessage(true);
      }
    } catch (error) {
      setResponseMessage('An error occurred while sending the message.');
      setShowMessage(true);
    }
  };
  return (
    <div className={styles.schedulecontainer}>
    <h1 className={styles.title}>Schedule a demo</h1>
    <div className={styles.formContainer}>
   
      <div className={styles.scheduleimageContainer}>
        <img
          src={scheduledemoimg}// Replace with the actual image path
          alt="Partner illustration"
          className={styles.image}
        />
      </div>
      <form className={styles.scheduleform} onSubmit={handleSubmit}>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <label htmlFor="firstName" className={styles.label}>
          <span className={styles.required}>*</span>&nbsp;FIRST NAME
          </label>
          <input
            type="text"
            id="firstName"
            className={`${styles.scheduleinput} ${
              errors.firstName ? styles.inputError : ""
            }`}
            placeholder="Enter your first name"
            value={formData.firstName}
            onChange={handleChange}
          />
  
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="lastName" className={styles.label}>
            LAST NAME
          </label>
          <input
            type="text"
            id="lastName"
            className={`${styles.scheduleinput} ${
              errors.lastName ? styles.inputError : ""
            }`}
            placeholder="Enter your last name"
            value={formData.lastName}
            onChange={handleChange}
          />
          
        </div>
      </div>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <label htmlFor="companyName" className={styles.label}>
            COMPANY NAME
          </label>
          <input
            type="text"
            id="companyName"
            className={`${styles.scheduleinput} ${
              errors.companyName ? styles.inputError : ""
            }`}
            placeholder="Where do you work?"
            value={formData.companyName}
            onChange={handleChange}
          />
         
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="companyEmail" className={styles.label}>
          <span className={styles.required}>*</span>&nbsp;COMPANY EMAIL
          </label>
          <input
            type="email"
            id="companyEmail"
            className={`${styles.scheduleinput} ${
              errors.companyEmail ? styles.inputError : ""
            }`}
            placeholder="yourname@company.com"
            value={formData.companyEmail}
            onChange={handleChange}
          />
          
        </div>
      </div>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <label htmlFor="country" className={styles.label}>
            COUNTRY
          </label>
          <input
            type="text"
            id="country"
            className={`${styles.scheduleinput} ${
              errors.country ? styles.inputError : ""
            }`}
            placeholder="Select"
            value={formData.country}
            onChange={handleChange}
          />
         
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="companyWebsite" className={styles.label}>
            COMPANY WEBSITE
          </label>
          <input
            type="text"
            id="companyWebsite"
            className={`${styles.scheduleinput} ${
              errors.companyWebsite ? styles.inputError : ""
            }`}
            placeholder="www.companyname.com"
            value={formData.companyWebsite}
            onChange={handleChange}
          />
        
        </div>
      </div>
      <div className={styles.buttonRow}>
        <button type="submit" className={styles.scheduleButton}>
        Schedule a demo
        </button>
      </div>
      {showMessage && (
              <div className={styles.responseMessage}>
                <span>{responseMessage}</span>
                <button className={styles.closeButton} onClick={() => setShowMessage(false)}>X</button>
              </div>
            )}
    </form>
    </div>
  </div>
  )
}

export default ScheduleDemopage