// integrations in menubar
export const fetchIntegrationMenuDetails = async () => {
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/integrations?menu=true`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch details");
      }
      return await response.json();
    } catch (error) {
      console.error("Error in fetchCardDetails:", error);
      throw error; // Re-throw error for further handling
    }
  };
 

// systems in menubar  
export const fetchSystemMenuDetails = async () => {
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/system-list?menu=true`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch details");
      }
      return await response.json();
    } catch (error) {
      console.error("Error in fetchCardDetails:", error);
      throw error; // Re-throw error for further handling
    }
  };
 
// workflows in menu bar  
export const fetchWorkflowmMenuDetails = async () => {
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/workflows-list?menu=true`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch details");
      }
      return await response.json();
    } catch (error) {
      console.error("Error in fetchCardDetails:", error);
      throw error; // Re-throw error for further handling
    }
  };
 
// function in menu bar  
export const fetchFunctionsMenuDetails = async () => {
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/functions?menu=true`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch details");
      }
      return await response.json();
    } catch (error) {
      console.error("Error in fetchCardDetails:", error);
      throw error; // Re-throw error for further handling
    }
  };
 