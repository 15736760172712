import React from 'react'
import { useState, useEffect, useRef } from 'react'
import datawrangler1 from '../../Assets/Images/Datawrangler/datawrangler1.svg'
import datawrangler2 from '../../Assets/Images/Datawrangler/datawrangler2.svg'
import datawrangler3 from '../../Assets/Images/Datawrangler/datawrangler3.svg'
import datawrangler4 from '../../Assets/Images/Datawrangler/datawrang1.svg'
import datawrangler5 from '../../Assets/Images/Datawrangler/datawrang2.svg'
import datawrangler6 from '../../Assets/Images/Datawrangler/datawrang3.svg'
import logo1 from "../../Assets/Images/Datawrangler/logo1.svg"
import logo2 from "../../Assets/Images/Datawrangler/logo2.svg"
import logo3 from "../../Assets/Images/Datawrangler/logo3.svg"
import styles from './dataWranglerpage.module.css'
const DataWranglerPage = () => {
  const featuresData = [
    {
      label: [logo1, logo2, logo3],
      labeMinWidths: ["80px", "150px", "204px"],
      labelWidths: ["80px", "150px", "286px"],
      labelHeights: ["70px", "25px", "53px"],
      description:
        "<p>A self-managed, scalable solution for your data flow. Design, manage, and automate scalable data pipelines with ease. Seamlessly connect to over 150 data sources, load data to your preferred destination, perform transformations, and automatically deliver outputs to downstream applications.</p>",
      image: datawrangler1,
    },
    {
      label: "Gather. Gauge. Prep. Enhance",
      subLabel: "Transform data headaches into productivity",
      description: "<p>In today’s fast-paced, data-driven world, receiving data from multiple sources and systems in varied formats is the norm, but it shouldn’t slow you down. <span class='rmb_run_p'>RUN</span><span class='rmb_my_p'>MY</span><span class='rmb_bot_p'>BOT®</span>’s intuitive Data Wrangler engine is your go-to solution for automating data prep and streamlining data management.</p>",
      image: datawrangler2,
    },
    {
      label: [],
      description: [
        "<p><span class='rmb_run_p'>RUN</span><span class='rmb_my_p'>MY</span><span class='rmb_bot_p'>BOT®</span>'s Data Wrangler engine goes beyond basic data intake by proactively analyzing incoming data to detect inconsistencies, errors, and anomalies at an early stage. This early validation of data quality allows for the identification and resolution of issues such as missing values, incorrect formats, and data duplication before the data enters your system. By ensuring clean and reliable input from the start, you prevent downstream problems, reduce the need for rework, and maintain data integrity throughout the entire workflow.</p>",

        "<p>Data Exploration & Profiling is essential for understanding the structure, quality, and content of your data. <span class='rmb_run_p'>RUN</span><span class='rmb_my_p'>MY</span><span class='rmb_bot_p'>BOT®</span>'s Data Wrangler engine enables in-depth exploration, helping you identify patterns, outliers, and data quality issues early. By providing insights into data types, distributions, and relationships, it ensures you can address problems proactively and optimize your data for analysis, reporting, and decision-making.</p>",
        "<p>By automating data preparation, <span class='rmb_run_p'>RUN</span><span class='rmb_my_p'>MY</span><span class='rmb_bot_p'>BOT®</span> eliminates the need for time-consuming manual processes, ensuring that your data is automatically cleaned, organized, and structured according to predefined rules and formats. This automation minimizes human error, standardizes the handling of data from multiple sources, and significantly accelerates the preparation process. As a result, teams can focus on higher-value tasks rather than manually correcting or reformatting data. By streamlining this critical step, <span class='rmb_run_p'>RUN</span><span class='rmb_my_p'>MY</span><span class='rmb_bot_p'>BOT®</span> enhances overall operational efficiency, reduces the risk of data inconsistencies or inaccuracies, and enables faster decision-making based on reliable, ready-to-use data.</p>"
      ],
      image: [datawrangler4, datawrangler5, datawrangler6],
    },
  ];

  // State to track visible index for each feature with multiple descriptions/images
  const [featuresVisibleIndex, setFeaturesVisibleIndex] = useState(
    featuresData.map(() => 0)
  );

  // Handle scroll logic for features with multiple descriptions/images
  const handleFeatureScroll = (featureIndex, event) => {
    // Check if the current feature has multiple descriptions
    const currentFeature = featuresData[featureIndex];
    const hasMultipleDescriptions = Array.isArray(currentFeature.description) &&
      currentFeature.description.length > 1;

    if (hasMultipleDescriptions) {
      setFeaturesVisibleIndex(prevIndexes => {
        const newIndexes = [...prevIndexes];

        if (event.deltaY > 0) {
          // Scroll down
          newIndexes[featureIndex] = Math.min(
            newIndexes[featureIndex] + 1,
            currentFeature.description.length - 1
          );
        } else {
          // Scroll up
          newIndexes[featureIndex] = Math.max(
            newIndexes[featureIndex] - 1,
            0
          );
        }

        return newIndexes;
      });
    }
  };

  return (
    <div className={styles.featuresContainer}>
      {featuresData.map((feature, index) => (
        <div
          key={index}
          className={`${styles.featureSection} ${index % 2 !== 0 ? styles.rightImage : styles.leftImage
            }`}
        >
          {/* Text Content */}
          <div className={styles.textContent}>
            <div className={styles.featureLabel}>
              {Array.isArray(feature.label) ? (
                <div className={styles.labelImages}>
                  {feature.label.map((imgSrc, i) => (
                    <img
                      key={i}
                      src={imgSrc}
                      alt={`Label ${i + 1}`}
                      className={styles.labelImage}
                      style={{
                        width: "100%",
                        height: feature.labelHeights?.[i] || "auto",
                        minWidth: feature.labeMinWidths?.[i] || "auto",
                        maxWidth: feature.labelWidths?.[i] || "auto"
                      }}
                    />
                  ))}
                </div>
              ) : (
                feature.label
              )}
            </div>
            {feature.subLabel && (
              <div className={styles.featureSubLabel}>{feature.subLabel}</div>
            )}
            {/* Description with scroll */}
            <div
              className={`${styles.featureDescriptionContainer} ${Array.isArray(feature.description) ? styles.scrollable : ''
                }`}
              onWheel={(event) => handleFeatureScroll(index, event)}
            >
              {Array.isArray(feature.description) ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: feature.description[featuresVisibleIndex[index]],
                  }}
                  className={styles.featureDescription}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: feature.description }}
                  className={styles.featureDescription}
                />
              )}
            </div>
          </div>

          {/* Image Content */}
          <div className={styles.imageContainer}>
            {Array.isArray(feature.image) ? (
              <img
                src={feature.image[featuresVisibleIndex[index]]}
                alt={
                  typeof feature.label === "string"
                    ? feature.label
                    : "Feature Image"
                }
                className={styles.featureImage}
                style={{ width: feature.imageWidth || "100%" }}
              />
            ) : (
              <img
                src={feature.image}
                alt={
                  typeof feature.label === "string"
                    ? feature.label
                    : "Feature Image"
                }
                className={styles.featureImage}
                style={{ width: feature.imageWidth || "100%" }}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DataWranglerPage;