import React from 'react'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './bySystemsPage.module.css'
import CardsGrid from '../../components/SystemsCard/CardsGrid';
import bysystemsheader from '../../Assets/Images/Systems/systemHeader.svg'
import CustomSearch from '../../components/CustomSearch/CustomSearch';
import { fetchEnterPrizeList } from '../../apiServices/EnterpriseList/apiCalls';

const BysystemsPage = () => {
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [filteredData, setFilteredData] = useState([]);
  const [remainingData, setRemainingData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Fetch image data from API
    const fetchImages = async () => {
      try {
        const result = await fetchEnterPrizeList("api/system-list"); // Adjust endpoint
        setData(result.systems);

        // Filter specific titles into filteredData
        const filteredDataInit = result.systems.filter((item) =>
          ["Microsoft Dynamics", "SAP S/4 HANA", "Oracle EBS", "Oracle NetSuite"].includes(item.title)
        );
        setFilteredData(filteredDataInit);

        // Set remaining data
        const remainingDataInit = result.systems.filter(
          (item) => !filteredDataInit.some((filteredItem) => filteredItem.id === item.id)
        );
        setRemainingData(remainingDataInit);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchImages();
  }, []);

  const handleCardFilter = (id) => {
    // Find the clicked item in remainingData
    const clickedItem = remainingData.find((item) => item.id === id);

    if (clickedItem) {
      // Replace the first item in filteredData with the clicked item
      const updatedFilteredData = [clickedItem, ...filteredData.slice(1)];

      // Update remainingData
      const updatedRemainingData = [
        ...remainingData.filter((item) => item.id !== id),
        filteredData[0], // Add the removed item from filteredData back to remainingData
      ];

      setFilteredData(updatedFilteredData);
      setRemainingData(updatedRemainingData);
    }
  };

  // Filter remainingData based on searchQuery
  const filteredRemainingData = remainingData.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCardClick = (id) => {
  
    navigate(`/integration/${id}`); // Navigate to a new page based on the ID

  }
  return (
    <div className={styles.systemspageContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.imageContainer}>
          <svg className={styles.center_bolt} xmlns="http://www.w3.org/2000/svg" width="70" height="69" viewBox="0 0 70 69" fill="none">
            <path d="M20.583 10.6842C22.7647 9.38673 25.0872 8.40253 27.4822 7.73303L28.9036 1.01124L40.4421 0.812012L42.1021 7.47959C44.5204 8.06602 46.8767 8.96855 49.1034 10.1907L54.9346 6.42852L63.2369 14.3182L59.6226 20.1854C60.9412 22.3333 61.9409 24.6199 62.6209 26.9776L69.4497 28.3764L69.6525 39.7326L62.8779 41.3673C62.2823 43.7469 61.365 46.0651 60.1238 48.2574L63.9452 53.9971L55.9301 62.1684L49.9686 58.6104C47.7869 59.9079 45.4637 60.8914 43.068 61.5609L41.6466 68.2827L30.1088 68.4819L28.4481 61.8136C26.0305 61.2272 23.6742 60.3239 21.4475 59.1025L15.6163 62.8647L7.31399 54.9749L10.9283 49.1078C9.60972 46.9599 8.61006 44.674 7.92999 42.3163L1.10119 40.9175L0.898438 29.5613L7.67302 27.9259C8.2686 25.5463 9.18663 23.2274 10.4271 21.0358L6.60505 15.2961L14.6201 7.12478L20.583 10.6842ZM27.9229 24.7438C21.5644 29.7816 21.7354 39.2596 28.2728 44.0721C32.739 47.3598 39.006 47.2521 43.3532 43.8095C49.7145 38.7724 49.5441 29.2895 43.0033 24.4777C38.5364 21.1907 32.2701 21.2991 27.9229 24.7438Z" fill="#E27DDD" />
          </svg>
          <svg className={styles.bolts_grey} xmlns="http://www.w3.org/2000/svg" width="60" height="59" viewBox="0 0 60 59" fill="none">
            <path d="M17.1093 8.7901C18.9784 7.67849 20.9687 6.83509 23.0215 6.26133L24.2394 0.501911L34.1264 0.331543L35.5492 6.0445C37.6211 6.54716 39.6401 7.32086 41.548 8.3677L46.545 5.14409L53.6589 11.9046L50.562 16.9319C51.6919 18.7721 52.5487 20.7314 53.1309 22.7518L58.9818 23.95L59.1557 33.6814L53.3505 35.0824C52.8401 37.1212 52.0538 39.1079 50.9907 40.9861L54.2657 45.905L47.3975 52.907L42.2893 49.8587C40.4195 50.971 38.4293 51.8137 36.3764 52.3874L35.1585 58.1468L25.2722 58.3179L23.8494 52.6042C21.7775 52.1016 19.7592 51.3279 17.8506 50.2818L12.8536 53.5054L5.73972 46.7449L8.83662 41.7176C7.7067 39.8773 6.84993 37.9188 6.26702 35.8976L0.415373 34.6987L0.242188 24.968L6.04737 23.5671C6.55777 21.5283 7.34414 19.5416 8.40718 17.6633L5.13217 12.7452L12.0004 5.74319L17.1093 8.7901ZM23.3989 20.8377C17.9506 25.1546 18.097 33.2759 23.6988 37.3993C27.5257 40.2167 32.8958 40.1244 36.6214 37.174C42.0725 32.8577 41.9267 24.7322 36.3215 20.6089C32.4938 17.7929 27.1237 17.8865 23.3989 20.8377Z" fill="#EBEBEB" />
          </svg>
          <svg className={styles.bolts_grey2} xmlns="http://www.w3.org/2000/svg" width="60" height="59" viewBox="0 0 60 59" fill="none">
            <path d="M17.1093 8.7901C18.9784 7.67849 20.9687 6.83509 23.0215 6.26133L24.2394 0.501911L34.1264 0.331543L35.5492 6.0445C37.6211 6.54716 39.6401 7.32086 41.548 8.3677L46.545 5.14409L53.6589 11.9046L50.562 16.9319C51.6919 18.7721 52.5487 20.7314 53.1309 22.7518L58.9818 23.95L59.1557 33.6814L53.3505 35.0824C52.8401 37.1212 52.0538 39.1079 50.9907 40.9861L54.2657 45.905L47.3975 52.907L42.2893 49.8587C40.4195 50.971 38.4293 51.8137 36.3764 52.3874L35.1585 58.1468L25.2722 58.3179L23.8494 52.6042C21.7775 52.1016 19.7592 51.3279 17.8506 50.2818L12.8536 53.5054L5.73972 46.7449L8.83662 41.7176C7.7067 39.8773 6.84993 37.9188 6.26702 35.8976L0.415373 34.6987L0.242188 24.968L6.04737 23.5671C6.55777 21.5283 7.34414 19.5416 8.40718 17.6633L5.13217 12.7452L12.0004 5.74319L17.1093 8.7901ZM23.3989 20.8377C17.9506 25.1546 18.097 33.2759 23.6988 37.3993C27.5257 40.2167 32.8958 40.1244 36.6214 37.174C42.0725 32.8577 41.9267 24.7322 36.3215 20.6089C32.4938 17.7929 27.1237 17.8865 23.3989 20.8377Z" fill="#EBEBEB" />
          </svg>
          <svg className={styles.bolts_greysm} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path d="M8.41457 4.58839C9.34455 4.03505 10.3344 3.61546 11.3559 3.33034L11.962 0.464359L16.8809 0.379883L17.5884 3.22263C18.6191 3.47255 19.6237 3.85764 20.5734 4.3786L23.0592 2.77489L26.5989 6.1386L25.0578 8.63992C25.6203 9.55582 26.0463 10.5301 26.3363 11.5355L29.2473 12.1318L29.3339 16.9732L26.4454 17.6701C26.1913 18.6846 25.8006 19.673 25.2711 20.6072L26.9002 23.0543L23.483 26.5377L20.9415 25.0213C20.0116 25.5746 19.021 25.9935 17.9995 26.2793L17.3934 29.1453L12.4745 29.2305L11.7663 26.3878C10.7357 26.1378 9.73105 25.7527 8.78206 25.2318L6.29624 26.8355L2.75653 23.4718L4.29758 20.9705C3.73508 20.0546 3.30916 19.0802 3.01911 18.0749L0.108077 17.4786L0.0214844 12.6372L2.90999 11.9403C3.16414 10.9258 3.55556 9.93738 4.08427 9.00317L2.45451 6.55607L5.87172 3.07268L8.41457 4.58839ZM11.5438 10.5822C8.83345 12.7301 8.90597 16.7711 11.6931 18.8226C13.5974 20.2242 16.2691 20.1785 18.1227 18.7106C20.8345 16.5627 20.762 12.5204 17.9735 10.4689C16.0692 9.06794 13.3975 9.1144 11.5438 10.5822Z" fill="#F5F5F5" />
          </svg>
          <svg className={styles.bolts_greysxm} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M6.76074 4.22386C7.50909 3.77893 8.30601 3.44101 9.12829 3.21151L9.61616 0.905202L13.5755 0.836914L14.145 3.12491C14.975 3.32626 15.7832 3.63602 16.547 4.0549L18.5478 2.76376L21.3962 5.47135L20.1557 7.48479C20.6084 8.22187 20.9513 9.00613 21.1843 9.81573L23.5272 10.2959L23.5969 14.1925L21.2723 14.7536C21.0681 15.5702 20.7527 16.3657 20.3275 17.1176L21.6391 19.0874L18.8885 21.8914L16.8427 20.6707C16.0944 21.1163 15.2967 21.4536 14.4752 21.6831L13.9873 23.9894L10.028 24.0577L9.45847 21.7697C8.62845 21.5683 7.82026 21.2585 7.05642 20.8397L5.05565 22.1308L2.20727 19.4239L3.44771 17.4105C2.99504 16.6734 2.6522 15.8891 2.41847 15.0802L0.0755547 14.6001L0.00585938 10.7035L2.33047 10.1424C2.53463 9.32575 2.85002 8.53023 3.27523 7.77835L1.96368 5.80856L4.71421 3.00453L6.76074 4.22386ZM9.27894 9.04837C7.09725 10.7767 7.15568 14.0292 9.39933 15.6808C10.9319 16.8086 13.082 16.772 14.5744 15.5906C16.7568 13.8623 16.6991 10.6084 14.454 8.95685C12.9207 7.82904 10.7707 7.86635 9.27894 9.04837Z" fill="#EBEBEB" />
          </svg>
          <svg className={styles.bolts_greyl} xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
            <path d="M11.6505 6.77762C12.9395 6.01096 14.3123 5.42946 15.728 5.03311L16.5679 1.06043L23.3876 0.942871L24.3689 4.88386C25.7981 5.23023 27.1906 5.76386 28.5071 6.48616L31.9538 4.26223L36.8607 8.92552L34.7248 12.3934C35.5041 13.6628 36.0948 15.0144 36.4968 16.4076L40.5328 17.2341L40.6525 23.9461L36.6488 24.912C36.2968 26.3178 35.7547 27.6885 35.0212 28.9839L37.2796 32.3765L32.5424 37.2059L29.0189 35.1031C27.7291 35.8704 26.3563 36.4512 24.9406 36.8469L24.1007 40.8195L17.2818 40.9371L16.3004 36.9961C14.8713 36.649 13.4788 36.1154 12.163 35.3938L8.71622 37.6177L3.80933 32.9552L5.94527 29.4872C5.16594 28.2179 4.57528 26.867 4.1733 25.473L0.137268 24.6466L0.0175781 17.9346L4.02194 16.968C4.37394 15.5621 4.91671 14.1914 5.64958 12.8961L3.39045 9.50351L8.12767 4.67408L11.6505 6.77762ZM15.9885 15.0869C12.2306 18.0642 12.332 23.6659 16.1955 26.51C18.8355 28.4531 22.5385 28.3897 25.1088 26.3545C28.8682 23.3772 28.7682 17.7727 24.9019 14.9285C22.2612 12.9869 18.5574 13.0517 15.9885 15.0869Z" fill="#F5F5F5" />
          </svg>
          <svg className={styles.bolts_greysmm} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M7.2652 3.68138C8.01356 3.23645 8.81047 2.89854 9.63274 2.66904L10.1206 0.362732L14.0799 0.294434L14.6495 2.58243C15.4795 2.78378 16.2877 3.09355 17.0515 3.51243L19.0523 2.22128L21.9014 4.92887L20.6609 6.94232C21.1136 7.67941 21.4564 8.46365 21.6895 9.27325L24.0324 9.75338L24.1021 13.65L21.7775 14.2111C21.5733 15.0278 21.2579 15.8233 20.8327 16.5751L22.1443 18.5449L19.3944 21.349L17.3486 20.1282C16.6002 20.5732 15.8026 20.9111 14.9811 21.1406L14.4931 23.4469L10.5339 23.5152L9.96364 21.2272C9.13433 21.0258 8.32544 20.7161 7.5616 20.2972L5.56081 21.5883L2.71245 18.8814L3.95287 16.868C3.5002 16.1309 3.15735 15.3467 2.92433 14.5378L0.581414 14.0576L0.511719 10.161L2.83633 9.59992C3.04049 8.78328 3.35591 7.98776 3.78113 7.23589L2.46955 5.26609L5.22006 2.46206L7.2652 3.68138ZM9.78412 8.50589C7.60242 10.2342 7.66087 13.4867 9.90452 15.1383C11.4371 16.2661 13.5871 16.2295 15.0789 15.0482C17.262 13.3198 17.2036 10.066 14.9592 8.41437C13.4259 7.28656 11.2759 7.32387 9.78412 8.50589Z" fill="#EBEBEB" />
          </svg>
          <svg className={styles.bolts_greyxsmm} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6.89162 3.49828C7.63997 3.05335 8.43688 2.71544 9.25916 2.48593L9.74702 0.179627L13.7063 0.111328L14.2759 2.39933C15.1059 2.60067 15.9141 2.91044 16.6779 3.32932L18.6787 2.03818L21.5271 4.74506L20.2866 6.75851C20.7393 7.4956 21.0821 8.27985 21.3152 9.08945L23.6581 9.56957L23.7278 13.4662L21.4032 14.0273C21.199 14.8439 20.8843 15.6395 20.4584 16.3913L21.7699 18.3611L19.0201 21.1652L16.9743 19.9444C16.2259 20.39 15.4283 20.7273 14.6067 20.9568L14.1189 23.2631L10.1596 23.3314L9.59006 21.0434C8.76004 20.842 7.95182 20.5323 7.18798 20.1134L5.18722 21.4045L2.33814 18.6976L3.5786 16.6842C3.12593 15.9471 2.78306 15.1629 2.54933 14.354L0.206414 13.8738L0.136719 9.97719L2.46133 9.4161C2.66549 8.59946 2.98091 7.80394 3.40613 7.05207L2.09455 5.08228L4.84506 2.27824L6.89162 3.49828ZM9.41053 8.32279C7.22884 10.0518 7.28724 13.3036 9.53089 14.9552C11.0635 16.083 13.2135 16.0464 14.706 14.8651C16.8884 13.1367 16.8307 9.88285 14.5856 8.23126C13.0523 7.10345 10.9023 7.14077 9.41053 8.32279Z" fill="#F5F5F5" />
          </svg>
          <img src={bysystemsheader} alt='' className={styles.bystemsimg}/>
        </div>

        <h1 className={styles.heading}>Our solutions cover multiple systems and technologies</h1>
        <p className={styles.description}>
          Explore, search, and uncover new automation opportunities across various systems with our comprehensive library of pre-built integrations.
        </p>



      </div>
      <div className={styles.cardsGridContainer} >
        {data?.length > 0 ? (
           <CardsGrid cards={filteredData} handleCardclick={handleCardClick} />
        ) : (
          <p>No data found.</p>
        )}

      </div>

      <div className={styles.searchimgwrapper}>
        <div className={styles.SearchContainer}>
          <CustomSearch
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            placeholder="Search by title..."
          />
        </div>

        {/* Image Grid Container */}
        <div className={styles.imageGridContainer}>
        {filteredRemainingData.length > 0 ? (
          filteredRemainingData.map((image) => (
            <div
              key={image.id}
              className={styles.imageGridItem}
              onClick={() => handleCardFilter(image.id)}
            >
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}attachments/${image.logo}`}
                alt={`Image ${image.title}`}
              />
           
            </div>
          ))
        ) : (
          <p>No images found for "{searchQuery}"</p>
        )}
      </div>


      </div>



    </div>
  )
}

export default BysystemsPage