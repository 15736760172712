import React, { useState, useEffect } from "react";
import styles from "./testimonial.module.css";

const TestimonialSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [paused, setPaused] = useState(false); // State to pause transition

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/testimonials`; // Replace with your actual API endpoint

    // Fetch API to get data
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer your-token' // Add any necessary headers
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setTestimonialsData(data.testimonials);

      })
      .catch((error) => {
        console.error('Error occurred:', error);
      });
  }, []);


  // Automatically rotate cards every 5 seconds
  useEffect(() => {
    if (!paused) {
      // If the 'paused' state is false, start the interval
      const interval = setInterval(() => {
        setActiveIndex((prevIndex) =>
          (prevIndex + 1) % testimonialsData.length // Increment active index, cycling through the testimonials
        );
      }, 5000); // The interval runs every 5 seconds
      return () => clearInterval(interval); // Clear the interval when the component unmounts or dependencies change
    }
  }, [testimonialsData, paused]); // The effect depends on 'testimonialsData' and 'paused'


  const getPositionClass = (index) => {
    const relativeIndex = (index - activeIndex + testimonialsData.length) % testimonialsData.length;
    if (relativeIndex === 0) return styles.left;
    if (relativeIndex === 1) return styles.center;
    if (relativeIndex === 2) return styles.right;
    return styles.hidden;
  };
  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      (prevIndex - 1 + testimonialsData.length) % testimonialsData.length
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      (prevIndex + 1) % testimonialsData.length
    );
  };
  const getFirstSentence = (title) => {
    const firstSentence = title.split(".")[0];
    return firstSentence;
  };
  const handleMouseleave = () => {
    setPaused(false);
    setHoveredIndex(null);
  }
  const handleCardClick = (index) => {
    const relativeIndex =
      (index - activeIndex + testimonialsData.length) % testimonialsData.length;
    if (relativeIndex === 0) {
      // Left card clicked
      setActiveIndex((prevIndex) =>
        (prevIndex - 1 + testimonialsData.length) % testimonialsData.length
      );
    } else if (relativeIndex === 2) {
      // Right card clicked
      setActiveIndex((prevIndex) =>
        (prevIndex + 1) % testimonialsData.length
      );
    }
    // If the center card is clicked, do nothing
  };

  return (
    <div className={styles.testimonialMain}
      onMouseEnter={() => setPaused(true)} // Pause on hover
      onMouseLeave={handleMouseleave} // Resume on leave
    >
      <div className={styles.testimonial_inner}>
        <button
          className={styles.leftButton}
          onClick={handlePrev}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
            <path d="M8.485 11.3652L0 5.68245L8.485 -0.000338554L9.9 0.946681L2.828 5.68245L9.9 10.4182L8.485 11.3652Z" fill="#94AFD4" />
          </svg>
        </button>
        <button
          className={styles.rightButton}
          onClick={handleNext}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
            <path d="M1.41539 11.3652L9.90039 5.68245L1.41539 -0.000338554L0.000391006 0.946681L7.07239 5.68245L0.000391006 10.4182L1.41539 11.3652Z" fill="#94AFD4" />
          </svg>
        </button>
        <div className={styles.testimonialSection}>

          <div className={styles.carouselContainer}>

            <div className={styles.carousel}>
              {testimonialsData.length > 0 &&
                testimonialsData.map((testimonial, index) => (
                  <div
                    key={index}
                    className={`${styles.card} ${getPositionClass(index)}`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onClick={() => handleCardClick(index)}

                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        margin: "5px 10px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="49"
                        height="90"
                        viewBox="0 0 49 42"
                        fill="none"
                      >
                        <path
                          d="M19.9864 23.1592V41.0181H0V26.9181C0 19.2864 1.10245 13.7579 3.31548 10.3408C6.21194 5.78858 10.8048 2.33886 17.0859 0.00378418L21.6421 5.98385C17.8426 7.29377 15.0478 9.24644 13.2538 11.8378C11.4598 14.4292 10.459 18.2043 10.2515 23.1592H19.9864Z"
                          fill="#C8D7EA"
                        />
                        <path
                          d="M47.3399 23.1595V41.0184H27.3535V26.9184C27.3535 19.2867 28.456 13.7582 30.669 10.341C33.5695 5.7848 38.1583 2.33914 44.4435 0L48.9997 5.98006C45.2001 7.28998 42.4054 9.24265 40.6114 11.834C38.8133 14.4254 37.8166 18.2005 37.6091 23.1555H47.344L47.3399 23.1595Z"
                          fill="#C8D7EA"
                        />
                      </svg>
                    </div>

                    <blockquote className={styles.blockquote}>
                      {getFirstSentence(testimonial.title)}
                    </blockquote>
                    <div className={styles.clientInfo}>
                      <img
                        src={`${process.env.REACT_APP_API_BASE_URL}attachments/${testimonial.image_icon}`}
                        alt="image"
                      />
                      <div style={{ textAlign: "left", paddingRight: "10px" }}>
                        <h3>{testimonial.name}</h3>
                        <p style={{ marginTop: "1rem", marginBottom: "1rem" }}>{testimonial.designation}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

          </div>

        </div>
      </div>


      <div className={styles.dots_fullTitle}>
        <div className={styles.dots}>
          {testimonialsData.map((_, index) => (
            <span
              key={index}
              className={`${styles.dot} ${(index - activeIndex + testimonialsData.length) % testimonialsData.length === 1
                ? styles.activeDot
                : ""
                }`}
              onClick={() => setActiveIndex(index)}
            ></span>
          ))}
        </div>

        {/* Show the full title below the dots when hovered */}
        {hoveredIndex !== null && (
          <div className={styles.fullTitle}>
            {/* Container for text and image aligned in a row */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',     // Stack items vertically
                justifyContent: 'space-between',  // Distribute space between elements

              }}
            >
              <div
                className={styles.fullTitle_content}

              >
                <p style={{ width: '70%', marginRight: '20px', flex: 1 }}>{testimonialsData[hoveredIndex].description}</p>
                {/* src={`https://cms-v2.actionfi.com/attachments/${testimonialsData[hoveredIndex].image}`} */}
                {/* <img src=`${process.env.REACT_APP_API_BASE_URL}attachments/${testimonialsData[hoveredIndex].image}`} */}
                <div className={styles.fullTitle_imgcontainer}>
                  <svg width="245" height="245" viewBox="0 0 245 245" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.blue_circle}>
                    <g opacity="0.8">
                      <circle cx="122.5" cy="122.5" r="122.5" fill="#E9EFF7" />
                    </g>
                  </svg>
                  <svg width="201" height="201" viewBox="0 0 201 201" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.pink_circle}>
                    <g  opacity="0.8">
                      <circle cx="100.5" cy="100.5" r="100.5" fill="#F4D7F5" fill-opacity="0.7" />
                    </g>
                  </svg>


                  <img src={`${process.env.REACT_APP_API_BASE_URL}attachments/${testimonialsData[hoveredIndex].image}`}
                    alt="image"
                    style={{
                      maxWidth: '300px',
                      marginTop: "100px",   // Set the maximum width for the image
                      height: 'auto',            // Maintain the aspect ratio of the image
                      display: 'block',          // Ensure the image is block-level for alignment
                      position:"relative",
                      zIndex:1000
                    }}
                  />

                </div>

              </div>

              {/* Bottom box */}
              <div
                style={{

                  backgroundColor: '#FFFFFF', // White background color
                  
                  // marginTop: '10px', // Optional: to add space above the bottom box
                  display: 'flex',   // Use flexbox to align content inside the bottom box
                  flexDirection:"column",
                  justifyContent: 'space-between',  // Space the paragraphs left and right
                  padding: '20px 40px',   // Padding inside the bottom box
                  position:"relative",
                  zIndex:"100",
                  gap:"10px"
                }}
              >
                {/* Left-side paragraph inside bottom box */}
                <p className={styles.bottomname}
                  style={{
                    flex: 1,

                    textAlign: 'right', // Align text to the left

                    margin: 0,         // Remove default margin


                  }}
                >
                  {testimonialsData[hoveredIndex].name}
                </p>

                {/* Right-side paragraph inside bottom box */}
                <p className={styles.bottomDesignmation}
                  style={{
                    flex: 1,

                    textAlign: 'right', // Align text to the right
                    margin: 0,          // Remove default margin
                  }}
                >
                  {testimonialsData[hoveredIndex].designation}
                </p>
              </div>
            </div>
          </div>
        )}


      </div>

    </div>
  );
};

export default TestimonialSection;
