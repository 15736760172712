import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import Connect from'../../Assets/Images/platform/Connect.svg'
import Analyze from'../../Assets/Images/platform/Analyze.svg'
import Analyze_hover from'../../Assets/Images/platform/Analyze_hover.svg'

import DataWrangler from'../../Assets/Images/platform/DataWrangler.svg'
import RunmybotForSAP from'../../Assets/Images/platform/RunmybotForSAP.svg'
import RunmybotForSAP_hover from'../../Assets/Images/platform/RunmybotForSAP_hover.svg'
import WorkflowBuilder from'../../Assets/Images/platform/WorkflowBuilder.svg'
import WorkflowBuilder_hover from'../../Assets/Images/platform/WorkflowBuilder_hover.svg'
import EventPro from'../../Assets/Images/platform/EventPro.svg'
import EventPro_hover from'../../Assets/Images/platform/EventPro_hover.svg'
import platformmain from'../../Assets/Images/platform/platformMain.svg'
import Connect_hover from'../../Assets/Images/platform/Connect_hover.svg'
import DataWrangler_hover from'../../Assets/Images/platform/DataWrangler_hover.svg'
import Wheel_pink from'../../Assets/Images/platform/Wheel_pink.svg'
import Wheel_grey from'../../Assets/Images/platform/Wheel_grey.svg'
import Wheel_outline from'../../Assets/Images/platform/Wheel_outline.svg'

import styles from './platformPage.module.css'

const platformData = [
    {
      label: "Connect",
      content: "Integration related page. Extract /Transform/Load",
      image: Connect,
      hoverImage:Connect_hover,
      path:"/connect"
    },
    {
      label: "Data Wrangler",
      content: "Data prep/ enhancement related page",
      image: DataWrangler,
      hoverImage:DataWrangler_hover,
      path:"/datawrangler"
    },
    // {
    //   label: "Workflow Builder",
    //   content: "Create Workflows/ Customize Integrations",
    //   image: WorkflowBuilder,
    //   hoverImage:WorkflowBuilder_hover,
    //   path:"/workflowbuilder"
    // },
    // {
    //   label: "Analyze",
    //   content: "AI/ Insights / Reporting",
    //   image: Analyze,
    //   hoverImage:Analyze_hover,
    //   path:"/ai-analyze"
    // },
    {
      label: "RUNMYBOT for SAP",
      content: "Hyperautomation-as-a-Service solution built on SAP BTP without needing back-end customizations in SAP",
      image: RunmybotForSAP,
      hoverImage:RunmybotForSAP_hover,
      path:"/runmybotforsap"
    },
    // {
    //   label: "Event Pro",
    //   content: "Event based Advanced Scheduler and Choreography Engine for Hyperautomation",
    //   image: EventPro,
    //   hoverImage:EventPro_hover,
    //   path:"/runmybot-eventpro"

    // },
  ];

const PlatformPage = () => {
  const navigate=useNavigate();
  const [hoveredImage, setHoveredImage] = useState(null);
  const handleClick =(path)=>{

    navigate(`${path}`)
  }
  return (
    <div className={styles.platform}>
    <div className={styles.platformHeader}>
         Platform
    </div>
    <div>

    </div>
    <div className={styles.platformWrapper}>
        {/* Rotating Wheels */}
        {/* Left-side Wheels */}
        <img src={Wheel_pink} alt="Rotating Wheel Pink Left" className={`${styles.wheel} ${styles.pink} ${styles.left}`} />
        <img src={Wheel_grey} alt="Rotating Wheel Grey Left" className={`${styles.wheel} ${styles.grey} ${styles.left}`} />
        <img src={Wheel_outline} alt="Rotating Wheel Outline Left" className={`${styles.wheel} ${styles.outline} ${styles.left}`} />

        {/* Central Platform Image */}
        <div className={styles.platformImage}>
          <img src={platformmain} alt="Platform" style={{width:"100%"}} />
        </div>

        {/* Right-side Wheels */}
        <img src={Wheel_pink} alt="Rotating Wheel Pink Right" className={`${styles.wheel} ${styles.pink} ${styles.right}`} />
        <img src={Wheel_grey} alt="Rotating Wheel Grey Right" className={`${styles.wheel} ${styles.grey} ${styles.right}`} />
        <img src={Wheel_outline} alt="Rotating Wheel Outline Right" className={`${styles.wheel} ${styles.outline} ${styles.right}`} />
      </div>
   
    <div className={styles.platformGroup}>   
    {platformData.map((platform, index) => (
      <div key={index} className={styles.solutionContainer} onClick={()=>{handleClick(platform.path)}}>
      <div className={styles.imageWrapper}>

        {/* {solution.image && <img src={solution.image} alt={solution.label} />} */}
        {/* On hover, change image */}
        <img
          src={hoveredImage === index ? platform.hoverImage : platform.image}
          alt={platform.label}
          onMouseEnter={() => setHoveredImage(index)} // Mouse over: set hover image
          onMouseLeave={() => setHoveredImage(null)}   // Mouse leave: revert to original image
        />
      </div>
      <h3>{platform.label}</h3>
      <p>{platform.content}</p>
    </div>
          ))}
    </div>

  </div>
  )
}

export default PlatformPage