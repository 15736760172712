import React from 'react'
import { useState, useEffect } from 'react';
import workflowsHeader from '../../Assets/Images/workflowbuilder/byworkflowheader.svg'
import styles from './workFlows.module.css'
import { fetchWorkflows } from '../../apiServices/workflows/apiCalls';
import AutomationLibraryPage from '../Automationlibrary/AutomationLibraryPage';
const WorkflowsPage = () => {
  const heading = "By Workflows";
  const label = "Pre - made workflows for a variety of use cases";
  const [details, setDetails] = useState(null);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchWorkflows();
        setDetails(data.workflows);
      } catch (error) {
        console.error("Error fetching card details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (loading) return <div className={styles.loading_div}>Loading...</div>;
  if (!details) return <div className={styles.noDataFound} >No details found.</div>;
  const description = "Go through our extensive library of workflows that were created to fit particular needs of our customers.Easily automate your processes across docs, images, modern & legacy apps. Build automations faster with easy-to-use tools, using clicks not code. Increase the pace of innovation throughout your org through collaboration."

  const handleclick = () => {
    window.open(`https://staging.runmybot.com/login`, "_blank", "noopener,noreferrer");
  }
  return (
    <div className={styles.workflowspageContainer}>
      {/* Heading Section */}
      <h1 className={styles.heading}>{heading}</h1>

      {/* Content Section */}
      <div className={styles.contentContainer}>
        {/* Image on the Left */}
        <div className={styles.imageContainer}>
          <img src={workflowsHeader} alt={label} className={styles.image} />
        </div>

        {/* Label and Description on the Right */}
        <div className={styles.textContainer}>
          <div className={styles.label}>{label}</div>
          <p className={styles.description}>{description}</p>
        </div>
      </div>

      {/* Grid Section */}
      <div className={styles.gridContainer}>
        {details?.map((item) => (
          <div key={item.id} className={styles.gridItem}>
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}attachments/${item.workflow_logo}`}
              alt={item.title}
              className={styles.image}
            />
            <div className={styles.gridLabel}>{item.title}</div>
          </div>
        ))}
      </div>
      {/* <div className={styles.createWorkflow}>
        <span>Still don't find the workflow you are looking for? Got you</span> */}
      {/* onClick={()=>{handleclick()}} */}
      {/* <button className={styles.createWorkflowBtn} >Create Your Workflow</button>
      </div> */}
      <AutomationLibraryPage />
    </div>
  );
}

export default WorkflowsPage