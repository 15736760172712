import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import styles from "./IntegrationCard.module.css";

const IntegrationCards = ({ integrationData }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Number of items per row
    slidesToScroll: 4,
    rows: 2, // Number of rows
    autoplay: true,
    autoplaySpeed: 5000, // Auto slide every 5 seconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll:2,
          rows: 2,
        },
      },
      {
        breakpoint: 1390,
        settings: {
          slidesToShow: 3,
          slidesToScroll:3,
          rows: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll:1,
          rows: 2,
        },
      },
    ],
};

  const navigate = useNavigate();

  // Define items as objects with id and name properties
  const items = Array.from({ length: 12 }, (_, index) => ({
    id: index + 1,
    name: `Item ${index + 1}`,
  }));

  const handleLearnMore = (id) => {
    navigate(`/integration/${id}`); // Navigate to a new page based on the ID
  };

  return (
    <div className={styles.integrationContainer}>
      <h2 className={styles.heading}>Featured Integrations</h2>
      <div className={styles.carousel_container}>
        <Slider {...settings}>
          {integrationData.map((card, index) => (

            <div key={`current-${index}`} className={styles.card} style={{width:"auto",display:"contents"}}>

              <div className={styles.cardImage}>
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}attachments/${card.logo}`}
                  alt={card.title}
                />
              </div>
              <p>{card.title}</p>
              <div className={styles.learnmorecontainer} onClick={() => handleLearnMore(card.slug)} >
                <button
                  className={styles.learnmoreBtn}
                // Pass the card ID
                >
                  Learn More
                </button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 21.9248V21.9248C7.029 21.9248 3 17.8958 3 12.9248V12.9248C3 7.9538 7.029 3.9248 12 3.9248V3.9248C16.971 3.9248 21 7.9538 21 12.9248V12.9248C21 17.8958 16.971 21.9248 12 21.9248Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 12.9248H8"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 9.9248L16 12.9248L13 15.9248"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

          ))}
        </Slider>
      </div>
    </div>
  );
};

export default IntegrationCards;
