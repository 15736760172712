import React, { useState, useEffect } from 'react';
import { fetchImages } from '../../apiServices/Awards/apiCalls';
import styles from './Awards.module.css';

const Awards = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Fetch the images on component mount
    const getImages = async () => {
      const fetchedImages = await fetchImages();
      setImages(fetchedImages);
    };

    getImages();
  }, []);

  // useEffect(() => {
  //   // Move to the next set of images every 3000ms
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 2) % images.length);
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [images]);

  if (images.length === 0) {
    return <div>Loading...</div>;
  }

  // Get the current and next images for display
  const displayedImages = [
    images[currentIndex],
    images[(currentIndex + 1) % images.length],
  ];

  return (
    <div className={styles.awardContainer}>
      <div className={styles.awardCarousel}>
        {images.map((image, index) => (
          <div key={index} className={styles.carouselItem}>
            <a href={image.link} target="_blank" rel="noopener noreferrer" style={{ width: "70%" }}>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}attachments/${image.logo}`}
                alt={image.title}
                className={styles.carouselImage}
              />
            </a>

          </div>
        ))}
      </div>
    </div>
  );
};

export default Awards;
