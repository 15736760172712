import React from 'react'
import { useState } from 'react'
import styles from './contactUspage.module.css'
import contactusimg from '../../Assets/Images/Contactus/contactus.svg'

const ContactUspage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    message: "",
  });
  const [responseMessage, setResponseMessage] = useState(''); // Holds the success or error message
  const [showMessage, setShowMessage] = useState(false); // Controls visibility of the message box
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Company email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    return newErrors;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [id]: value }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (id === "email" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        delete newErrors.email;
      } else if (value.trim()) {
        delete newErrors[id];
      }

      return newErrors;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);

    const apiBody = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      company_email: [formData.email],
      company_name: formData.companyName,
      message: formData.message,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/enquiry `, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(apiBody),
      });

      if (response.ok) {
        setResponseMessage('Message sent successfully!');
        setShowMessage(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          companyName: "",
          message: "",
        });
      } else {
        setResponseMessage('Failed to send the message. Please try again.');
        setShowMessage(true);
      }
    } catch (error) {
      setResponseMessage('An error occurred while sending the message.');
      setShowMessage(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className={styles.contactuscontainer}>
        <h1 className={styles.title}>Get in touch</h1>
        <div className={styles.formContainer}>

          <div className={styles.contactusimageContainer}>
            <img
              src={contactusimg}// Replace with the actual image path
              alt="Partner illustration"
              className={styles.image}
            />
          </div>
          <form className={styles.contactusform} onSubmit={handleSubmit}>
            <div className={styles.inputRow}>
              <div className={styles.inputGroup}>
                <label htmlFor="firstName" className={styles.label}>
                  <span className={styles.required}>*</span>&nbsp;First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  className={`${styles.contactusinput} ${errors.firstName ? styles.inputError : ""
                    }`}
                  placeholder="Enter your first name"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                {/* {errors.firstName && <span className={styles.error}>{errors.firstName}</span>} */}
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="lastName" className={styles.label}>Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  className={styles.contactusinput}
                  placeholder="Enter your last name"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={styles.inputGroup}>
                <label htmlFor="email" className={styles.label}>
                  <span className={styles.required}>*</span>&nbsp;Company Email
                </label>
                <input
                  type="email"
                  id="email"
                  className={`${styles.contactusinput} ${errors.email ? styles.inputError : ""
                    }`}
                  placeholder="yourname@company.com"
                  value={formData.email}
                  onChange={handleChange}
                />
                {/* {errors.email && <span className={styles.error}>{errors.email}</span>} */}
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="companyName" className={styles.label}>Company Name</label>
                <input
                  type="text"
                  id="companyName"
                  className={styles.contactusinput}
                  placeholder="Where do you work?"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="message" className={styles.label}>Message</label>
              <input
                type="text"
                id="message"
                className={styles.contactusinput}
                placeholder="Write your message here"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <div className={styles.buttonRow}>
              <button
                type="submit"
                className={styles.contactusButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send Now"}
              </button>
            </div>
          </form>
          {showMessage && (
            <div className={styles.responseMessage}>
              <span>{responseMessage}</span>
              <button className={styles.closeButton} onClick={() => setShowMessage(false)}>X</button>
            </div>
          )}
        </div>
      </div>

    </>

  )
}

export default ContactUspage