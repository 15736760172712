import React, { useState } from "react";
import styles from "./integrationTile.module.css";

const IntegrationCard = ({ card }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prev) => !prev); // Only affects this card
  };
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "..."; // Joins words properly
    }
    return text;
  };
  return (
    <div key={card.id} className={styles.box}>
      <div className={styles.rectangle}>
        <img
          src={`${process.env.REACT_APP_API_BASE_URL}attachments/${card.integration_logo}`}
          alt={card.title}
          className={styles.image}
        />
        <div className={styles.label}>
          <span className={styles.textWrapper}>{card.title}</span>
        </div>
        <div className={styles.detail}>
          <p className={styles.textWrapper} style={{ marginTop: "10px" }}>
            Initial Release: {card.integration_year}
          </p>
          <p className={styles.textWrapper} style={{ marginTop: "15px" }}>
            Version: {card.integration_version}
          </p>
        </div>

        {/* Description with Read More */}
        <div className={styles.description}>
          <p className={`${styles.textWrapper} ${expanded ? styles.expanded : ""}`}>
            {/* {expanded ? card.integration_description : `${card.integration_description.substring(0, 150)}...`} */}
            {expanded ? card.integration_description : truncateText(card.integration_description, 25)}
          </p>
          {/* {card.integration_description.length > 150 && ( */}
          {card.integration_description.split(" ").length > 25 && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button onClick={toggleExpand} className={styles.readMore}>
                {expanded ? "Less" : "More"}
              </button>
            </div>
          )}
        </div>
        {/* <div className={styles.description}>
          <p className={`${styles.textWrapper} ${expanded ? styles.expanded : ""}`}>
            {!expanded && card.integration_description.length > 120 ? `${card.integration_description.substring(0, 120)}...` : card.integration_description}
            {card.integration_description.length > 120 && (

              <button onClick={toggleExpand} className={styles.readMore}>
                {expanded ? "Less" : "More"}
              </button>

            )}
          </p>

        </div> */}
      </div>
    </div>
  );
};

export default IntegrationCard;
