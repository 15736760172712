import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styles from "./customdropdownbar.module.css";

const CustomDropdownBar = ({ dropdownData, handleNavigation, setIsMenuOpen }) => {
  const navigate = useNavigate();

  const handleTitleClick = (path) => {
    if (path) {
      handleNavigation(path); // Call handleNavigation passed from Navbar
    }
  };

  const handleItemClick = (path, title, e, setIsMenuOpen) => {

    e.preventDefault(); // Prevent default behavior
    const titlePathMap = {
      "By Integrations": `/Integration/${path}`,
      "By Functions": `functions/${path}`,
      "By Workflows": "/workflows",
      "By Systems": `/Integration/${path}`,
    };

    const navigationPath = titlePathMap[title] || path;

    if (navigationPath) {
      navigate(navigationPath); // Navigate using React Router's `navigate` function
    }
    setIsMenuOpen(false)
  };

  return (
    <div className={styles.dropdownContainer}>
      {dropdownData.map((section, index) => (
        <div key={index} className={styles.section}>
          {/* Title */}
          <p onClick={() => handleTitleClick(section.path)} style={section.title === 'RUNMYBOT for SAP' ? { padding: "5px 20px" } : {}}>{section.title}</p>
          {/* {section.items && (<>
            <hr />


            <ul> */}
          {/* {section.items?.map((item, idx) => (
                <li key={idx} onClick={(e) => handleItemClick(item.slug, section.title, e, setIsMenuOpen)}  >
                  <NavLink
                    to={item.path}

                    className={({ isActive }) => (isActive ? styles.activeLink : "")}
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))} */}
          {/* <div className={styles.viewAllbuttton} onClick={() => handleTitleClick(section.path)}><svg style={{ width: "25px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path style={{ fill: "#232326" }} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg> */}
          {/* <svg  className={styles.uparrow} xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z"/></svg> */}
          {/* </div> */}
          {/* </ul>

          </>

          )} */}

        </div>
      ))}
    </div>
  );
};

export default CustomDropdownBar;
