/*
purpose of this: homepage of website
Author: Jinto Jose
Completed date: 
*/


import React from 'react';
import { useEffect, useState } from 'react';
import styles from './homepage.module.css'; // Import the CSS module
import { useNavigate } from 'react-router-dom';
import RotatingText from '../components/Layout/RotatingText';
import CounterDisplay from '../components/Layout/counter/CounterDisplay';
import platformVideo from '../Assets/Videos/INFOGRAPHICSOFRUNMYBOT-vp9-chrome.webm'
import platformVideo1 from '../Assets/Videos/INFOGRAPHICSOFRUNMYBOT-hevc-safari.mp4'
import TestimonialSection from '../components/Testimonials/TestimonialSection';
import whatwegoodat from '../Assets/Images/whatwegoodat.svg';
import MoreThanPlatform from '../components/morethanaplatform/MoreThanPlatform';
import ImageCarousel from '../components/AutomationRunsAt/ImageCarousel';
import Spinner from '../components/Animations/Spinner/Spinner';
import TestimonialNew from '../components/Testimonials/TestimonialNew';
// import useGlobalTextReplace from '../useGlobalTextReplace';


const HomePage = () => {


    const texts = [
        "Scalable",
        "Purpose-built integration and automation as a service",
        "Fully managed to elevate your operations seamlessly"

    ];
    const navigate = useNavigate()

    const featuresArray = ['Integration', 'AI Analytics', 'RPA Automations', 'Advanced Schedulers', 'Data Transformation'];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [grid, setGrid] = useState([]); // Holds the 4x4 grid of images
    useEffect(() => {
        // Check if there's a hash in the URL
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                // Scroll to the element with the corresponding id
                element.scrollIntoView({
                    behavior: 'smooth'


                });
            }
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {

            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % featuresArray.length); // Change text

            }, 500); // Match fade-out duration in CSS
        }, 1000); // Interval to switch text

        return () => clearInterval(interval);
    }, [featuresArray]);
    useEffect(() => {
        // Fetch image data from API
        const fetchImages = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/end-users`);
                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }
                const data = await response.json();
                // Extract logo URLs from API response
                const images = data.end_users.map((user) => `${process.env.REACT_APP_API_BASE_URL}attachments/${user.logo}`);
                setGrid(images);
            } catch (err) {

            }
        };
        fetchImages();
    }, []);
    const handleExplorefurther = () => {
        navigate('/whyactionfi')
    }
    const handleclick = () => {
        navigate('/scheduledemo')

        // window.open(`https://exp.runmybot.com/accounts/login/`, "_blank", "noopener,noreferrer");

    }
    return (<>
        <div className={styles.homeContainerMain}>
            <div className={styles.homePage}>

                <div className={styles.textSection}>

                    <h1>The Smartest Way to
                        Connect Business Data</h1>
                    <div className="nonAnimation">
                        <div className={styles.moving_text}>Not just{' '} &nbsp;
                            <span className={styles.ovalBorder}>
                                {featuresArray[currentIndex]}
                            </span>{' '}

                            {' '}
                            , We do it all</div>
                    </div>
                    <button className={styles.ExpRmbButton} onClick={() => { handleclick() }} >Schedule a demo</button>
                </div>
                <div className={styles.imageSection}>
                    <Spinner />

                </div>
            </div>
            <div style={{ margin: "60px 0px ", textAlign: "center" }}>
                <RotatingText texts={texts} />
            </div>
            <div>
                <CounterDisplay />
            </div>
            <div className={styles.automationRunsat}>
                <p>Our automations run at</p>

                <div className={styles.sliders_wrapper}>
                    {grid.length > 0 ? (
                        <>
                            <ImageCarousel images={grid} showdiv={true} />

                        </>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>
            <div className={styles.whyactionfi}>
                <h1 style={{ marginTop: "0px" }}>Why actionfi?</h1>

                <p>For the last 10+ years, actionfi has consistently earned the trust of the integration and automation software market. With an ever growing customer list of 180+global customers. Organizations use actionfi to automate their business through the integration of systems and APIs to enable complicated and connect business processes.</p>
                <div className={styles.explorefurther}>
                    <button onClick={handleExplorefurther} style={{ cursor: "pointer" }}>Explore Further</button>
                </div>
            </div>
            <div >
                <div className={styles.whatwegoodat}>
                    <p >What are we good at?</p>
                    <div className={styles.imageSection}>
                        <img src={whatwegoodat} alt="What we are good at?" className={styles.image} />
                    </div>

                </div>
                <div id="testimonials" className={styles.testimonialContainer} >
                    <p>What our clients say about us.</p>
                    {/* <TestimonialNew /> */}
                    <TestimonialSection/>
                </div>
                <div className={styles.ourPlatform}>
                    <h1 >Our Platform</h1>
                    <p>
                        The RUNMYBOT platform offers end-to-end management, including PoC, solution design, implementation, and 24/7 support and maintenance. Subscription fees cover all upgrades. The platform delivers intelligent insights into your processes and workflows, empowering continuous improvement.
                    </p>
                    <video
                        autoPlay
                        loop
                        muted
                        style={{ width: '90%', height: 'auto' }}
                        playsInline
                    >
                        <source src={platformVideo} type="video/webm" />
                        <source src={platformVideo1} type='video/mp4; codecs="hvc1"' />
                        Your browser does not support the video tag.
                    </video>

                </div>
            </div>

        </div>
        <div className={styles.morethanplatform}>
            <p className={styles.morethanplatformp}>More than a platform</p>
            <MoreThanPlatform />
        </div>


        {/* <div style={{ width: "100%", overflow: "hidden" }} className={styles.wave}>

        </div> */}
    </>

    );
};

export default HomePage;
