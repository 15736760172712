export const fetchDropdownData = async (endpoint) => {

    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
      if (!response.ok) {
        throw new Error("Failed to fetch dropdown data");
      }
      return await response.json();
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      return { array1: [], array2: [] }; // Return default data
    }
  };
  
  export const fetchCardData = async (fnctn,erp,page=1,search) => {
    
    const sanitizedSearch = search !== undefined ? search : "";

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/automation-list?erp_slug=${erp}&page=1&function=${fnctn}&page=${page}&search_keyword=${sanitizedSearch}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch card data");
      }
      return await response.json();
    } catch (error) {
      console.error("Error fetching card data:", error);
      return []; // Return default data
    }
  };
  


 //function to display details of each card
export const fetchCardDetails = async (slug1, slug2) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/automation-details?automation_slug=${encodeURI(slug2)}&erp_slug=${encodeURI(slug1)}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch details");
    }
    return await response.json();
  } catch (error) {
    console.error("Error in fetchCardDetails:", error);
    throw error; // Re-throw error for further handling
  }
};


// run my bot for sap
export const fetchErpsapCardData = async (erp,page=1,search) => {
    
  const sanitizedSearch = search !== undefined ? search : "";

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/automation-list?erp=sap&erp_slug=${encodeURI(erp)}&page=${page}&page_session=n&search_keyword=${sanitizedSearch}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch card data");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching card data:", error);
    return []; // Return default data
  }
};
