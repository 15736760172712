import React from 'react';
import styles from './footer.module.css';
import { NavLink, } from "react-router-dom";
import actionfilogo from '../../../Assets/Images/actionfiLogo.png';
import ISOlogo from "../../../Assets/Images/ISO.svg"


const Footer = () => {




  return (
    <>





      <div className={styles.footerTopLeft}>

        <div className={styles.footerLogo}>
          <img src={actionfilogo} alt="ActionFi Logo" />
        </div>
        <div className={styles.footerTagline}>
          Grow your business with <br />
          smart data transfer
        </div>
      </div>


      <div className={styles.navList}>
        <div className={styles.navItem}>
          <NavLink
            to="platform"
            className={
              `${styles.navLink}`
            }
          >
            Platform
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="/company"
            className={
              `${styles.navLink}`
            }
          >
            Company
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="termsofuse"
            className={
              `${styles.navLink} `
            }
          >
            Terms of Use
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="solutions"
            className={
              `${styles.navLink} `
            }
          >
            Solutions
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="/whyactionfi"
            className={
              `${styles.navLink} `
            }
          >
            About Us
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="privacypolicy"
            className={
              `${styles.navLink} `
            }
          >
            Privacy Policy
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <a
            href='/#testimonials'
            //  onClick={() => handleNavigation('testimonials')}
            className={
              `${styles.navLink} `
            }
          // onClick={()=>handleNavigateToTestimonials}
          >
            Testimonial
          </a>
        </div>

        <div className={styles.navItem}>
          <NavLink
            to="/careers"
            className={
              `${styles.navLink} `
            }
          >
            Careers
          </NavLink>
        </div>

        <div className={styles.navItem}>
          <NavLink
            to="https://www.youtube.com/watch?v=o0OBse7UBG0"
            rel="noopener noreferrer"
            target="_blank"
            className={
              `${styles.navLink} `
            }
          >
            Videos
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="contactus"
            className={
              `${styles.navLink} `
            }
          >
            Contact Us
          </NavLink>
        </div>
      </div>



      <div >
        <img src={ISOlogo} alt="ISO Logo" />
      </div>






    </>
  );
};

export default Footer;
