export const fetchFunctions = async (slug) => {
  const slugValue = slug || "";  // If slug is undefined, use an empty string
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/functions?slug=${slugValue}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      return await response.json(); // Parse JSON data
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Propagate error to handle it in the component
    }
  };
  // apiService.js

export const fetchfunctionsIntgerationworkflow = async (itemId) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/functions?slug=${itemId}`, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error("Failed to fetch from First API");
    }
    return response.json();
  };
  
  // export const fetchIntegrations = async (itemId) => {
  //   const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/integrations/?id=${itemId}`, {
  //       method: "GET"
  
      
  //   });
  //   if (!response.ok) {
  //     throw new Error("Failed to fetch from Second API");
  //   }
  //   return response.json();
  // };
  