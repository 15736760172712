import React from 'react'
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import integrationHeader from '../../../Assets/Images/Integration/Integrationheader.svg'
import styles from './integrationTile.module.css'
import { fetchIntegrationDetails } from '../../../apiServices/Integrations/apiCalls';
import IntegrationCard from './IntegrationCard';

const IntegrationTile = () => {
  const { id } = useParams();

  const erpMapping = {
    "oracle-fusion": "Oracle Fusion",
    "sap": "SAP",
    "oracle-netsuite": "Oracle NetSuite",
    "microsoft-dynamics": "Microsoft Dynamics",
    "quickbooks": "Quickbooks",
    "jd-edwards": "JD Edwards",
    "acumatica": "Acumatica",
    "snowflake": "Snowflake",
    "sage-intacct": "Sage Intacct",
    "reconnet": "ReconNet",
    "ms-dynamics-business-central": "MS Dynamics Business Central",
    "ms-dynamics-nav": "MS Dynamics NAV",
    "odoo": "Odoo",
    "oracle-people-soft": "Oracle People Soft",
    "sap-s4-hana": "SAP S/4 HANA",
    "oracle-ebs": "Oracle EBS",
    "sap-ecc": "SAP ECC",
    "infor-m3": "Infor M3",
    "blackline": "Blackline",
    "cadency": "Cadency",
    "adra": "Adra",
    "ms-dynamics-finance-operations": "MS Dynamics Finance & Operations",
    "visma": "Visma"
  };

  const formattedERP = erpMapping[id] || id;

  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchIntegrationDetails(id);
        setDetails(data.integrations);
      } catch (error) {
        console.error("Error fetching card details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <div className={styles.loading_div}>Loading...</div>;
  if (!details) return <div className={styles.noDataFound} >No details found.</div>;
  return (
    <div className={styles.IntegrationtileContainer}>
      <div className={styles.contentContainer}>
        {/* <img src={integrationHeader} className={styles.integrationHeaderimage} alt='Integration' />
        <h1 className={styles.heading}>{formattedERP} Integration</h1>
        <p className={styles.description} >
          Explore and discover new automation possibilities with our extensive library of ready-to-use integrations with {formattedERP}.
        </p> */}
        <h1 className={styles.heading2}>Browse Our Prebuilt {formattedERP} Integrations</h1>
        <p className={styles.description2}>
          Seamlessly integrate {formattedERP} with any other application and automate data ingestion, data prep, and other core business processes.
        </p>
      </div>
      <div className={styles.integrationgrid}>
        {details?.map((card) => (
          <IntegrationCard card={card} />
        ))}
      </div>




    </div>
  )
}

export default IntegrationTile