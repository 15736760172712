/*
purpose of this: Solutions of website
Author: Jinto Jose
Completed date: 
*/

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './solutionPage.module.css'
import byfunction from '../../Assets/Images/solutions/byFunction.svg'
import byIntegartion from '../../Assets/Images/solutions/byIntegartion.svg'
import bySystems from '../../Assets/Images/solutions/bySystems.svg'
import byWorkflows from '../../Assets/Images/solutions/byWorkflows.svg'
import solutionmain from '../../Assets/Images/solutions/solutionMain.svg'
import byIntegartion_hover from '../../Assets/Images/solutions/byIntegartion_hover.svg'
import bySystems_hover from '../../Assets/Images/solutions/bySystems_hover.svg'
import byWorkflows_hover from '../../Assets/Images/solutions/byWorkflows_hover.svg'
import byfunction_hover from '../../Assets/Images/solutions/byFunction_hover.svg'
const solutionsData = [
  {
    label: "By Integrations",
    content: "Extract /Transform/Load",
    image: byIntegartion,
    hoverImage: byIntegartion_hover,
    path: "/connect"
  },
  // {
  //   label: "By Function",
  //   content: "Data prep/ enhancement related page",
  //   image: byfunction,
  //   hoverImage: byfunction_hover,
  //   path: "/functions"
  // },
  {
    label: "By Workflows",
    content: " View Available Workflows/ Create Workflows/ Customize Integrations",
    image: byWorkflows,
    hoverImage: byWorkflows_hover,
    path: "/workflows"
  },
  {
    label: "By Systems",
    content: "View Integrations by Systems",
    image: bySystems,
    hoverImage: bySystems_hover,
    path: "/Systems"
  },
];

const SolutionPage = () => {
  const navigate = useNavigate()
  const [hoveredImage, setHoveredImage] = useState(null);
  const handleimageclick = (path) => {
    navigate(`${path}`);
  }

  return (
    <div className={styles.solutions}>
      <div className={styles.solutionHeader}>
        Solutions
      </div>
      <div className={styles.solutionImage}>
        <img src={solutionmain} alt="solutions" style={{ maxWidth: "500px", width: "100%" }} />
      </div>

      <div className={styles.solutionGroup}>
        {solutionsData.map((solution, index) => (
          <div key={index} className={styles.solutionContainer}>
            <div className={styles.imageWrapper} onClick={() => { handleimageclick(solution.path) }}>

              {/* {solution.image && <img src={solution.image} alt={solution.label} />} */}
              {/* On hover, change image */}
              <img
                src={hoveredImage === index ? solution.hoverImage : solution.image}
                alt={solution.label}
                onMouseEnter={() => setHoveredImage(index)} // Mouse over: set hover image
                onMouseLeave={() => setHoveredImage(null)}   // Mouse leave: revert to original image
              />
            </div>
            <h3>{solution.label}</h3>
            <p>{solution.content}</p>
          </div>
        ))}
      </div>

    </div>
  )
}

export default SolutionPage