import React from 'react'
import { useEffect, useState } from 'react'
import styles from './runmybotSap.module.css'
import Haas_sap from '../../Assets/Images/RmbSap/Haas_sap.gif'
import fully_managed from '../../Assets/Images/RmbSap/fully_managed.svg'
import ImageMarquee from '../../components/ImageMarquee/ImageMarquee'

import HeaderImage from './HeaderImage'
import { fetchEndusers } from '../../apiServices/EndUsers/apiCalls'
import SapLibrary from './SapLibrary'


const featuresData = [
  {
    label: 'Fully Managed Hyperautomation-as-a-Service (HaaS) Platform Built on SAP BTP',
    description: "Take a different approach to automating Digital Workflows in the modern world that span regions, time zones, departments, systems, technologies and productivity tools with on-premise and on-the-cloud tasks. Achieve faster integrations with functional-specific SAP integrations out of the box, with structured and unstructured data support and AI/ML capabilities.",
    image: Haas_sap,
  },
]
const featuresData2 = [
  {
    label: 'A Fully Managed Platform at a Single Predictable Cost',
    description: "A fully managed, business-specific integration lifecycle from ideation to proof of concept, implementation, maintenance and 24/7 support. Your end-to-end scenario is Hyperautomated without any costly implementations, enhancements or customizations, and for a single fee. Save on unnecessary license and maintenance costs, training and implementations fees, overhead on statement of work and change request and thus avoid the costly tech debt you otherwise inherit.",
    image: fully_managed,
  },
]

const RunmybotsapPage = () => {
  const [imageData, setImagedata] = useState([])

  useEffect(() => {
    // Fetch image data from API
    const fetchImages = async () => {
      try {
        const result = await fetchEndusers('api/end-users'); // Adjust endpoint
        // Extract logo URLs from API response
        const images = result.end_users.map((user) => `${process.env.REACT_APP_API_BASE_URL}attachments/${user.logo}`);
        setImagedata(images)
      } catch (err) {

      }
    };

    fetchImages();
  }, []);
  return (
    <div className={styles.rmbsapMainconatiner}>
      <h1 className={styles.heading}><span className='rmb_run'>RUN</span><span className='rmb_my'>MY</span><span className='rmb_bot'>BOT®</span> for SAP</h1>
      <div className={styles.headerImgMain}>
        <HeaderImage />
      </div>

      <div className={styles.contentContainer}>



        <p className={styles.description}>
          <span class='rmb_run_p'>RUN</span><span class='rmb_my_p'>MY</span><span class='rmb_bot_p'>BOT®</span> For SAP is a Hyperautomation-as-a-Service solution built on SAP BTP to help achieve your hyperautomation goals without any back-end customizations in SAP. Now achieve your hyperautomation goals without writing any code.                </p>

      </div>
      <div className={styles.rmbsapSection2}>
        <div className={styles.sapfeaturesContainer}>
          {featuresData.map((feature, index) => (
            <div
              key={index}
              className={`${styles.featureSection} ${index % 2 === 0 ? styles.rightImage : styles.leftImage
                }`}
            >
              <div className={styles.textContent}>
                <div className={styles.featureLabel}>{feature.label}</div>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
              <div className={styles.imageContainer}>
                <img src={feature.image} alt={feature.label} className={styles.featureImage} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rmbsapSection3}>
        <h1 className={styles.heading}>Get Enhancements Delivered on SAP BTP without SAP Customizations</h1>
        <p className={styles.description}>
          Powered by its Event Streaming Choreography Engine, <span class='rmb_run_p'>RUN</span><span class='rmb_my_p'>MY</span><span class='rmb_bot_p'>BOT®</span> delivers Reports, Interfaces, Enhancements and Workflows out of the box without and back-end customizations in SAP. Integrate data from non-SAP systems and productivity tools with the help of custom connectors on SAP BTP, and achieve complex, business-specific integrations, data extractions, ETLs and postings in SAP.
        </p>
        <div className={styles.youtubeVideoContainer}>

        </div>
        <div className={styles.iframe_container}>
          <iframe className={styles.iframe_vdo} width="560" height="315" src="https://www.youtube.com/embed/o0OBse7UBG0?si=mZv4wQeyUrBkf73c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
      </div>
      <div className={styles.rmbsapSection4}>
        <div className={styles.sapfeaturesContainer}>
          {featuresData2.map((feature, index) => (
            <div
              key={index}
              className={`${styles.featureSection} ${index % 2 === 0 ? styles.rightImage : styles.leftImage
                }`}
            >
              <div className={styles.textContent}>
                <div className={styles.featureLabel}>{feature.label}</div>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
              <div className={styles.imageContainer}>
                <img src={feature.image} alt={feature.label} className={styles.featureImage} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rmbsapSection5} style={{ maxWidth: "100%" }}>
        <ImageMarquee images={imageData} speed={20} />
      </div>
      <div className={styles.rmb_sapSection6}>
        {/* <div className={styles.heading}>
            Connect with <span className='rmb_run'>RUN</span><span className='rmb_my'>MY</span><span className='rmb_bot'>BOT</span> and see
            powerful results
      
            </div> */}
        <SapLibrary />
      </div>

    </div>
  )
}

export default RunmybotsapPage