import React from 'react'
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import functionHeader from '../../Assets/Images/Functions/functionHeader.svg'
import styles from './byFunctionpage.module.css'
import { fetchFunctions } from '../../apiServices/functions/apiCalls';
const ByFunctionPage = () => {
  const { slug } = useParams(); // Default slug to an empty string if undefined
  const navigate = useNavigate(); // To update the URL
  const [details, setDetails] = useState([]);            // For storing functions
  const [integrations, setIntegrations] = useState([]);   // For storing integrations
  const [workflows, setWorkflows] = useState([]);         // For storing workflows
  const [selectedFunction, setSelectedFunction] = useState(null); // For storing the selected function
  const [loading, setLoading] = useState(false);          // For loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Step 1: Fetch functions initially when slug is empty
        const functionsData = await fetchFunctions();
        setDetails(functionsData.functions || []);

        // Step 2: If slug is provided, identify the function and fetch integrations and workflows
        if (slug) {
          const functionFound = functionsData.functions.find(f => f.slug === slug);

          if (functionFound) {
            // Mark this function as selected (active)
            setSelectedFunction(functionFound);

            // Fetch integrations and workflows based on the selected function
            const { integrations, workflows } = await fetchFunctions(slug);
            setIntegrations(integrations || []);
            setWorkflows(workflows || []);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]); // Fetch data whenever slug changes
  const handleCardClick = (functionSlug) => {
    // Update the URL with the new slug
    navigate(`/functions/${functionSlug}`);
  };
  // Show loading state or empty data message
  if (loading) return <div className={styles.loading_div}>Loading...</div>;
  if (!details || details.length === 0) return <div className={styles.noDataFound}>No details found.</div>;

  const label = "We have solutions that make your lives easier";
  const heading = "By Function";
  const description =
    "Stay ahead of the competition with faster innovation. Speed up strategic projects to reach the market sooner. Embrace transformative technologies shaping the future, and automate routine tasks that don’t add value—freeing up resources for what matters most.";

  return (
    <div className={styles.functionpageContainer}>
      {/* Heading Section */}
      <h1 className={styles.heading}>{heading}</h1>

      {/* Content Section */}
      <div className={styles.contentContainer}>
        {/* Image on the Left */}
        <div className={styles.imageContainer}>
          <img src={functionHeader} alt={label} className={styles.image} />
        </div>

        {/* Label and Description on the Right */}
        <div className={styles.textContainer}>
          <div className={styles.label}>{label}</div>
          <p className={styles.description}>{description}</p>
        </div>
      </div>

      {/* Grid Section */}
      <div className={`${styles.gridContainer} ${styles.grid_div}`}>
        {details.map((item) => (
          <div key={item.id} className={styles.gridItem} onClick={() => handleCardClick(item.slug)}>
            <div className={`${styles.gridItemImage}${item.slug === selectedFunction?.slug ? styles.activeCard : ''}`}>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}attachments/${item.logo}`}
                alt={item.title}
                className={styles.image}
              />
            </div>
            <div className={styles.gridLabel}>{item.title}</div>
          </div>
        ))}
      </div>

      {/* Display Workflows if available */}
      {workflows && workflows.length > 0 && (
        <div className={styles.workflowsContainer}>
          <h2>Workflows</h2>
          <div className={`${styles.gridContainer} ${styles.workflow_div}`}>
            {workflows.map((item) => (
              <div key={item.workflows_id} className={styles.gridItem}>
                <div className={styles.gridItemImage}>
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL}attachments/${item.workflows_logo}`}
                    alt={item.workflows_title}
                    className={styles.image}
                  />
                </div>
                <div className={styles.gridLabel}>{item.workflows_title}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Display Integrations if available */}
      {integrations && integrations.length > 0 && (
        <div className={styles.workflowsContainer}>
          <h2>Integrations</h2>
          <div className={styles.integrationgrid}>
            {integrations.map((card) => (
              <div key={card.integration_id} className={styles.box}>
                <div className={styles.rectangle}>
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL}attachments/${card.integration_logo}`}
                    alt={card.integration_title}
                    className={styles.image}
                  />
                  <div className={styles.integration_label}>
                    <span className={styles.textWrapper}>{card.integration_title}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ByFunctionPage;