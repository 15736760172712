import React, { useState, useEffect } from 'react'
import connect_anything from '../../Assets/Images/Connect/connect_anything.svg'
import styles from './connectPage.module.css'
import prebuilt_integrations from '../../Assets/Images/Connect/prebuilt_integrations.svg'
import purposebuilt_integrations from '../../Assets/Images/Connect/purposebuilt_integrations.svg'
import IntegrationCards from '../../components/IntegrationCard/IntegrationCards';
import { fetchEnterPrizeList } from '../../apiServices/EnterpriseList/apiCalls';

import { fetchEndusers } from '../../apiServices/EndUsers/apiCalls'

const featuresData = [
  {
    label: 'Prebuilt Integrations',
    description: "Discover endless automation possibilities with our extensive library of prebuilt integrations. Whether you're searching for specific tools or exploring new ways to streamline your workflows, our comprehensive integrations library has you covered. Easily connect systems, automate repetitive tasks, and enhance efficiency—all with preconfigured solutions designed for seamless integration. Unlock the full potential of automation for your business.",
    image: prebuilt_integrations,
  },
  {
    label: "Purpose-built Integrations",
    description: "Let us help you design custom integrations tailored precisely to your business needs. Using our state-of-the-art technology, we’ll build integrations aligned with your niche and detailed requirements, when pre-built solutions do not solve the problem.",
    image: purposebuilt_integrations,
  },
  // Add more features as needed
];

const ConnectPage = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    // Fetch image data from API
    const fetchImages = async () => {
      try {
        const result = await fetchEnterPrizeList('api/featured-system-list'); // Adjust endpoint

        setData(result.featured_systems)
      } catch (err) {

      }
    };

    fetchImages();
  }, []);
  const [imageData, setImagedata] = useState([])

  useEffect(() => {
    // Fetch image data from API
    const fetchImages = async () => {
      try {
        const result = await fetchEndusers('api/end-users'); // Adjust endpoint
        // Extract logo URLs from API response
        const images = result.end_users.map((user) => `${process.env.REACT_APP_API_BASE_URL}attachments/${user.logo}`);
        setImagedata(images)
      } catch (err) {

      }
    };

    fetchImages();
  }, []);
  const cards = Array.from({ length: 12 }, (_, i) => `Card ${i + 1}`);
  return (
    <div className={styles.connectContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.contentContainer_inner}>
          <h1 className={styles.heading}>Connect Anything</h1>
          <p className={styles.description}>
            A self-managed, scalable solution for your data flow. Design, manage, and automate scalable data pipelines with ease. Seamlessly connect to over 150 data sources, load data to your preferred destination, perform transformations, and automatically deliver outputs to downstream applications.
          </p>
          <img
            src={connect_anything}
            alt="Connect Illustration"
            className={styles.image}
          />
        </div>

      </div>
      <div className={styles.contentContainer2}>
        <div className={styles.featuresContainer}>
          {featuresData.map((feature, index) => (
            <div
              key={index}
              className={`${styles.featureSection} ${index % 2 === 0 ? styles.rightImage : styles.leftImage
                }`}
            >
              <div className={styles.textContent}>
                <div className={styles.featureLabel}>{feature.label}</div>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
              <div className={styles.imageContainer}>
                <img src={feature.image} alt={feature.label} className={styles.featureImage} />
              </div>
            </div>
          ))}
        </div>



      </div>
      {data.length > 0 && (
        <IntegrationCards integrationData={data} />
      )}




    </div>
  )
}

export default ConnectPage