import React from 'react';
import Navbar from './Header/Navbar';
import { useLocation } from 'react-router-dom';
// import Footer from './Footer'; // Import Footer component
import { Outlet } from 'react-router-dom'; // Import Outlet for nested routes
import styles from './layout.module.css'; // Import CSS module for layout styling
import Footer from './Footer/Footer';
import FooterBottom from './Footer/FooterBottom';
import ContactDetail from '../../pages/ContactUs/ContactDetail'

const Layout = () => {
  const location = useLocation()
  return (


    <>
      <div className={styles.layoutContainer}>
        {/* Header Section */}

        <Navbar />
      </div>
      {/* Main Content Section */}
      <main className={styles.mainContent}>
        <Outlet /> {/* Renders the matched child route content here */}
      </main>

      {/* Footer Section */}
      {location.pathname === "/" && (<div style={{ width: "100%", overflow: "hidden" }} className={styles.wave}>

      </div >)}
      {location.pathname === "/contactus" && (<ContactDetail />)}
      <footer className={styles.footer}>

        <div className={styles.footerContainer}>

          <Footer />

        </div>
      </footer>
      <footer className={styles.FooterBottomSection}>
        <FooterBottom />
      </footer>

    </>

  );
};

export default Layout;
