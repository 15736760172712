import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";

const MovingSVG = () => {
    const containerRef = useRef(null);
    const numberOfPrimarySVGs =2;
    const numberOfSecondarySVGs = 2;
    const svgWidth = 700; // SVG width
    const svgHeight = 700; // SVG height

    useEffect(() => {
        const container = containerRef.current;
        const primarySvgs = Array.from(container.querySelectorAll(".primary-moving-svg"));
        const secondarySvgs = Array.from(container.querySelectorAll(".secondary-moving-svg"));

        // Function to set a random position within the viewport
        const setRandomPosition = (element) => {
            const x = Math.random() * (window.innerWidth - 200);  // Random X position within bounds
            const y = Math.random() * (window.innerHeight - 200); // Random Y position within bounds
            gsap.set(element, { x, y });
        };

        // Function to animate an SVG to a random position with smooth transitions
        const animateToRandomPosition = (element) => {
            const x = Math.random() * (window.innerWidth - element.getBoundingClientRect().width);
            const y = Math.random() * (window.innerHeight - element.getBoundingClientRect().height);
            const duration = Math.random() * 3 + 2; // 2 to 5 seconds
            
            gsap.to(element, {
                x,
                y,
                duration,
                ease: "power1.inOut",
                onComplete: () => animateToRandomPosition(element),
            });
        };

        // Initialize and animate each primary SVG
        primarySvgs.forEach((svg) => {
            setRandomPosition(svg);
            animateToRandomPosition(svg);
        });

        // Initialize and animate each secondary SVG
        secondarySvgs.forEach((svg) => {
            setRandomPosition(svg);
            animateToRandomPosition(svg);
        });

        // Cleanup on unmount
        return () => {
            gsap.killTweensOf([...primarySvgs, ...secondarySvgs]);
        };
    }, []);

    return (
        <div
            ref={containerRef}
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: -1,
                pointerEvents: "none", // Prevent interactions
            }}
        >
            {/* Render multiple primary SVGs */}
            {Array.from({ length: numberOfPrimarySVGs }).map((_, index) => (
                <svg
                    width={svgWidth}
                    height={svgHeight}
                    key={`primary-${index}`}
                    className="primary-moving-svg"
                    viewBox="0 0 992 992"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_f_40_81)">
                        <circle cx="496" cy="496" r="182" fill="#F4D7F5" fillOpacity="2" />
                    </g>
                    <defs>
                        <filter
                            id="filter0_f_40_81"
                            x="0"
                            y="0"
                            width="992"
                            height="992"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                            />
                            <feGaussianBlur
                                stdDeviation="157"
                                result="effect1_foregroundBlur_40_81"
                            />
                        </filter>
                    </defs>
                </svg>
            ))}

            {/* Render multiple secondary SVGs */}
            {Array.from({ length: numberOfSecondarySVGs }).map((_, index) => (
                <svg
                    width={svgWidth}
                    height={svgHeight}
                    key={`secondary-${index}`}
                    className="secondary-moving-svg"
                    viewBox="0 0 992 992"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_f_40_81)">
                        <circle cx="496" cy="496" r="182" fill="#D4D6F4" fillOpacity="10" />
                    </g>
                    <defs>
                        <filter
                            id="filter0_f_40_81"
                            x="0"
                            y="0"
                            width="992"
                            height="992"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                            />
                            <feGaussianBlur
                                stdDeviation="157"
                                result="effect1_foregroundBlur_40_81"
                            />
                        </filter>
                    </defs>
                </svg>
            ))}
        </div>
    );
};

export default MovingSVG;
