import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./CustomScrollbar.css";

const CustomScrollbar = ({ children, height = "400px", thumbSize = "44px", trackWidth = "4px" }) => {
  const contentRef = useRef();
  const scrollbarRef = useRef();
  const [thumbPosition, setThumbPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const thumbHeight = parseInt(thumbSize, 10);

  useEffect(() => {
    const checkOverflow = () => {
      if (!contentRef.current) return; // Add null check
      const { scrollHeight, clientHeight } = contentRef.current;
      setIsOverflowing(scrollHeight > clientHeight);
    };
    
  
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
  
    return () => {
      window.removeEventListener("resize", checkOverflow); // Cleanup
    };
  }, [children]);
  

  // Handle content scroll and update thumb position
  const handleScroll = () => {
    if (!contentRef.current || !scrollbarRef.current) return; // Check null
  
    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    const maxThumbPosition = scrollbarRef.current.clientHeight - thumbHeight;
  
    const newThumbPosition = (scrollTop / (scrollHeight - clientHeight)) * maxThumbPosition;
    setThumbPosition(newThumbPosition);
  };
  

  // Handle dragging the thumb
  const handleMouseDown = (e) => {
    if (!scrollbarRef.current || !contentRef.current) return; // Safety check
  
    setIsDragging(true);
  
    const initialY = e.clientY;
    const startThumbPosition = thumbPosition;
  
    const handleMouseMove = (moveEvent) => {
      if (!scrollbarRef.current || !contentRef.current) return;
  
      const deltaY = moveEvent.clientY - initialY;
  
      const maxThumbPosition = scrollbarRef.current.clientHeight - thumbHeight;
      let newThumbPosition = startThumbPosition + deltaY;
  
      // Clamp thumb position within bounds
      newThumbPosition = Math.max(0, Math.min(newThumbPosition, maxThumbPosition));
  
      setThumbPosition(newThumbPosition);
  
      const { scrollHeight, clientHeight } = contentRef.current;
      const scrollableHeight = scrollHeight - clientHeight;
      const newScrollTop = (newThumbPosition / maxThumbPosition) * scrollableHeight;
  
      contentRef.current.scrollTop = newScrollTop;
    };
  
    const handleMouseUp = () => {
      setIsDragging(false);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };
  
  return (
    <div className="scroll-container" style={{ height, position: "relative", overflow: "hidden" }}>
      {/* Scrollable Content */}
      <div
        className="content"
        ref={contentRef}
        onScroll={handleScroll}
        style={{ overflowY: "auto", height: "100%" }}
      >
        {children}
      </div>

      {/* Custom Scrollbar */}
      {isOverflowing && (
        <div
          className="scrollbar"
          ref={scrollbarRef}
          style={{ position: "absolute", right: "10px", top: "0", height: "100%" }}
        >
          {/* Custom Thumb SVG */}
          <svg xmlns="http://www.w3.org/2000/svg" width={trackWidth} height="100%" fill="none">
            <path
              d={`M${parseInt(trackWidth, 10) / 2} 0L${parseInt(trackWidth, 10) / 2} 419`}
              stroke="url(#paint0_linear)"
              strokeWidth={trackWidth}
            />
            <defs>
              <linearGradient id="paint0_linear" x1="2.5" y1="0" x2="2.5" y2="419" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E27DDD" stopOpacity="0" />
                <stop offset="0.49" stopColor="#E27DDD" stopOpacity="0.32" />
                <stop offset="0.98" stopColor="#E27DDD" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={thumbSize}
            height={thumbSize}
            viewBox="0 0 44 44"
            className="scroll-thumb"
            style={{
              position: "absolute",
              top: `${thumbPosition}px`,
              cursor: "pointer",
            }}
            onMouseDown={handleMouseDown}
          >
            <circle cx="22" cy="22" r="22" fill="url(#paint0_radial)" />
            <defs>
              <radialGradient
                id="paint0_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(22 22) rotate(90) scale(14.1429)"
              >
                <stop stopColor="#E27DDD" />
                <stop offset="1" stopColor="#E27DDD" stopOpacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
};

CustomScrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  thumbSize: PropTypes.string,
  trackWidth: PropTypes.string,
};

export default CustomScrollbar;
