import React, { useEffect, useRef, useState } from 'react';
import worldmap from '../../Assets/Images/partners/worldMap.svg';
import './map-component.scss';

// Import location logos
import trintechlogo from '../../Assets/Images/partners/trintech.svg';
import kpmg from '../../Assets/Images/partners/kpmg.svg';
import odoo1 from '../../Assets/Images/partners/odoo.svg'
import retailcloud from '../../Assets/Images/partners/retailcloud.svg';
import sumtheory from '../../Assets/Images/partners/sumtheory.svg';
import optimusprice from '../../Assets/Images/partners/optimusprice.svg';
import aws1 from '../../Assets/Images/partners/aws1.svg';
import microsoft1 from '../../Assets/Images/partners/microsoft1.svg';
import google1 from '../../Assets/Images/partners/google1.svg';
import sap1 from '../../Assets/Images/partners/sap1.svg';
import cltrs from '../../Assets/Images/partners/cltrs.svg';
import acumatica from '../../Assets/Images/partners/acumatica.svg';
import progientlabs from '../../Assets/Images/partners/progientlabs.svg';
import uxp from '../../Assets/Images/partners/uxp.svg';
import zuora from '../../Assets/Images/partners/zuora.svg';
import digipresence from '../../Assets/Images/partners/digipresence.svg'

// Define locations with more detailed information
const locations = [
  {
    id: 'marker-san-francisco',
    name: 'San Francisco',
    left: '14%',
    top: '40%',
    animationDelay: '0.1s',
    logo: trintechlogo,
    className: 'marker-san-francisco'
  },
  {
    id: 'marker-denver',
    name: 'Denver',
    left: '3%',
    top: '14%',
    width: '50px',
    animationDelay: '0.2s',
    logo: zuora,
    className: 'marker-denver'
  },
  {
    id: 'marker-amsterdam',
    name: 'amsterdam',
    left: '43%',
    top: '13%',
    width: '40px',
    animationDelay: '0.3s',
    logo: kpmg,
    className: 'marker-amsterdam'
  },
  {
    id: 'marker-retailcloud',
    name: 'Dubai',
    left: '8%',
    top: '30%',
    animationDelay: '0.4s',
    logo: retailcloud,
    className: 'marker-retailcloud'
  },
  {
    id: 'marker-sumtheory',
    name: 'Peterborough',
    left: '6%',
    top: '40%',
    animationDelay: '0.4s',
    logo: sumtheory,
    className: 'marker-sumtheory'
  },
  {
    id: 'marker-optimus',
    name: 'Vilnius',
    left: '42.5%',
    top: '21%',
    animationDelay: '0.5s',
    logo: optimusprice,
    className: 'marker-optimus'
  },
  {
    id: 'marker-aws',
    name: 'washington',
    left: '17%',
    top: '17%',
    width: '40px',
    animationDelay: '0.7s',
    logo: aws1,
    className: 'marker-aws'
  },
  {
    id: 'marker-microsoft',
    name: 'Manila',
    left: '17%',
    top: '27%',
    animationDelay: '0.8s',
    logo: microsoft1,
    className: 'marker-microsoft'
  },
  {
    id: 'marker-sydney1',
    name: 'Sydney',
    left: '0%',
    top: '24%',
    animationDelay: '0.9s',
    logo: google1,
    className: 'marker-sydney'
  },
  {
    id: 'marker-sap',
    name: 'Sydney',
    left: '54%',
    top: '8.5%',
    animationDelay: '0.9s',
    logo: sap1,
    className: 'marker-sap'
  },
  {
    id: 'marker-odoo',
    name: 'Sydney',
    left: '49%',
    top: '16%',
    animationDelay: '0.9s',
    logo: odoo1,
    className: 'marker-odoo'
  },
  {
    id: 'marker-cltrs',
    name: 'Sydney',
    bottom: '50%',
    right: '27.5%',
    width: '40px',
    animationDelay: '0.9s',
    logo: cltrs,
    className: 'marker-cltrs'
  },
  {
    id: 'marker-sydney',
    name: 'Sydney',
    left: '25%',
    top: '18%',
    animationDelay: '0.9s',
    logo: acumatica,
    className: 'marker-sydney'
  }
  ,
  {
    id: 'marker-progient',
    name: 'Sydney',
    bottom: '42%',
    right: '23.5%',
    animationDelay: '0.9s',
    logo: progientlabs,
    className: 'marker-progient'
  }
  ,
  {
    id: 'marker-uxp',
    name: 'Sydney',
    left: '10.5%',
    top: '9%',
    animationDelay: '0.9s',
    logo: uxp,
    className: 'marker-uxp'
  },
  {
    id: 'marker-digi',
    name: 'dubai',
    right: '35.5%',
    top: '28.5%',
    width: '40px',
    animationDelay: '0.9s',
    logo: digipresence,
    className: 'marker-digi'
  }
];

const MapComponent = () => {
  const mapRef = useRef(null); // Ref for the map container
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Trigger animation when visible
        }
      },
      {
        threshold: 0.1, // Adjust visibility threshold as needed
      }
    );

    if (mapRef.current) {
      observer.observe(mapRef.current);
    }

    return () => {
      if (mapRef.current) {
        observer.unobserve(mapRef.current);
      }
    };
  }, []);
  return (
    <div className="map-component">
      <div id="places-map" className="map-container" ref={mapRef}>
        <div className="map">
          <img
            src={worldmap}
            alt="World Map"
            className="map-image"
          />
          <div className="map-pins">
            {locations.map((location) => (
              <div
                key={location.id}
                className="map-pin-wrapper"
                style={{
                  left: location.left,
                  top: location.top,
                  bottom: location.bottom,
                  right: location.right,
                  width: location.width || ''
                }}
              >
                <div
                  className={`map-location-marker 
              ${location.className} 
              ${isVisible ? 'map-animated map-bounce-in-down' : ''}`}
                  style={{
                    animationDelay: location.animationDelay,
                    backgroundImage: `url(${location.logo})`,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );


};

export default MapComponent;