import React from 'react';
import styles from './casestudies.module.css'; // Import CSS styles for case studies
import { useState, useEffect } from 'react';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { fetchCaseStudies } from '../../apiServices/CaseStudies/apiCalls';
const CaseStudies = () => {
  // JSON data inside the component
  const [caseStudies, setCaseStudies] = useState([]);
  const [imageIndexes, setImageIndexes] = useState({});

  const [hasNext, setHasNext] = useState(0); // Tracks if more pages are available
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page

  // Fetch initial dropdown data and card data
  useEffect(() => {
    async function fetchData() {

      const response = await fetchCaseStudies(1);
      setCaseStudies(response.case_studies); // Set the data to state
      setHasNext(response?.has_next || 0);
    }

    fetchData();
  }, []);


  useEffect(() => {
    // Set up interval to cycle through images for each study
    const intervals = caseStudies.reduce((acc, study) => {
      if (study.images.length > 1) {
        acc[study.id] = setInterval(() => {
          setImageIndexes((prevIndexes) => ({
            ...prevIndexes,
            [study.id]: (prevIndexes[study.id] + 1) % study.images.length || 0,
          }));
        }, 3000); // Change every 3 seconds
      }
      return acc;
    }, {});

    // Cleanup on unmount
    return () => Object.values(intervals).forEach(clearInterval);
  }, [caseStudies]);


  const loadMoreCards = async () => {
    const nextPage = currentPage + 1;
    const response = await fetchCaseStudies(nextPage);
    setCaseStudies((prevCards) => [
      ...prevCards,
      ...(response?.case_studies || []),
    ]);
    setHasNext(response?.has_next || 0);
    setCurrentPage(nextPage);
  };


  const handleViewPdf = (pdfPath) => {
    window.open(`${process.env.REACT_APP_API_BASE_URL}attachments/${pdfPath}`, '_blank');

  };

  return (
    <div className={styles.caseStudiesContainer}>


      {/* {caseStudies.case_studies?.map((study) => ( */}
      {caseStudies?.map((study) => (
        <>
          <h1 className={styles.mainHeading}>Case Studies</h1>
          <div key={study.id} className={styles.caseStudyCard}>
            <div className={styles.cardLeft}>
              {/* <h2 className={styles.cardHeading}>{study.title}</h2> */}
              <h2 className={styles.cardHeading} dangerouslySetInnerHTML={{ __html: study.title }} />
              {/* <p className={styles.cardDescription}>{study.description}</p> */}
              <p dangerouslySetInnerHTML={{ __html: study.description }} className={styles.cardDescription} />
              <div className={styles.buttonRow}>
                <button className={styles.readMoreBtn} onClick={() => handleViewPdf(study.file)} >View
                  <ArrowCircleRightOutlinedIcon className={styles.arrowIcon} />
                </button>

              </div>

            </div>
            <div className={styles.cardRight}>
              {study.images.length > 0 && (
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}attachments/${study.images[imageIndexes[study.id] || 0]}`}
                  alt={study.title}
                  className={styles.caseStudyImage}
                  style={{ transition: "opacity 1s ease-in-out", width: "85%" }}
                />
              )}
              {/* <img src={study.image} alt={study.title} className={styles.caseStudyImage} /> */}
            </div>


          </div>
        </>

      ))}
      {hasNext === 1 && (
        <div className={styles.loadMore} onClick={loadMoreCards}>

          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none" style={{ cursor: "pointer" }}>
            <circle cx="16.5" cy="16.5" r="16.5" fill="#7398C7" />
            <path d="M8 14.2864L16.5 22L25 14.2864L23.5835 13L16.5 19.4291L9.4165 13L8 14.2864Z" fill="white" />
          </svg>
        </div>

      )}
    </div>
  );
};

export default CaseStudies;
