import React, { useEffect, useState } from 'react'
import styles from './automationlibraryinner.module.css'
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { fetchCardDetails } from '../../apiServices/AutomationLibrary/apiCalls';

const AutomationLibraryInner = () => {
  const { route, slug1, slug2 } = useParams();

  const navigate = useNavigate()

  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCardDetails(slug1, slug2);
        setDetails(data);
      } catch (error) {
        console.error("Error fetching card details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug1, slug2]);

  if (loading) return <div className={styles.loading_div}>Loading...</div>;
  if (!details) return <div className={styles.noDataFound} >No details found.</div>;

  const handleBackClick = () => {
    if (route === "workflows") {
      navigate('/workflows')
    } else if (route === "runmybotforsap") {
      navigate('/runmybotforsap')
    }

  }

  return (
    <div className={styles.automationinnerContainer}>
      <div className={styles.headerLabel}>
        {details?.automation_title}
      </div>


      {/* Features Section */}
      <div className={styles.featuresContainer}>
        {details?.automation_scenario_details?.map((feature, index) => (
          <div
            key={index}
            className={`${styles.featureSection} ${index % 2 === 0 ? styles.rightImage : styles.leftImage
              }`}
          >

            <div className={styles.textContent}>

              <div
                dangerouslySetInnerHTML={{ __html: feature.description }}
                className={styles.featureDescription}

              />

            </div>
            <div className={styles.imageContainer}>
              <img src={`${process.env.REACT_APP_API_BASE_URL}attachments/${feature.icon}`} alt={feature.label} className={styles.featureImage} />
            </div>

          </div>
        ))}
      </div>
      <div className={styles.automation_backbtn}>
        <button onClick={handleBackClick}>Back to workflows</button>
      </div>

    </div>
  )
}

export default AutomationLibraryInner