import React, { useEffect, useRef, useState } from "react";
import styles from "./CounterDisplay.module.css";

const CounterDisplay = () => {
  const counterContainerRef = useRef(null);
  const [items, setItems] = useState([
    { endValue: 180, currentValue: 0, label: "Worldwide Customers", format: "default" },
    { endValue: 50, currentValue: 0, label: "Industries served", format: "default" },
    { endValue: 22, currentValue: 0, label: "ERP systems integrated", format: "default" },
    { endValue: 3000000, currentValue: 0, label: "Transactions processed every month", format: "million" }
  ]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          startCounting();
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    if (counterContainerRef.current) {
      observer.observe(counterContainerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const formatNumber = (value) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + " M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "k";
    } else {
      return value.toString();
    }
  };

  const animateValue = (item, duration) => {
    const startValue = 0;
    const endValue = item.endValue;
    const startTime = performance.now();

    const frame = () => {
      const currentTime = performance.now();
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      const currentValue = Math.floor(progress * (endValue - startValue) + startValue);

      setItems((prevItems) =>
        prevItems.map((prevItem) =>
          prevItem.label === item.label ? { ...prevItem, currentValue: formatNumber(currentValue) } : prevItem
        )
      );

      if (timeElapsed < duration) {
        requestAnimationFrame(frame);
      }
    };

    frame();
  };

  const startCounting = () => {
    items.forEach((item) => animateValue(item, 2000));
  };

  return (
    <div ref={counterContainerRef} className={styles.counterContainer}>
      <div className={styles.counterData}>
        {items.map((item, index) => (
          <div key={index} className={styles.counterItem}>

            <div className={styles.number}>{item.currentValue}+</div>
            <div className={styles.label}>{item.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CounterDisplay;
