
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./saplibrary.module.css";
import { fetchCardData, fetchDropdownData, fetchErpsapCardData } from '../../apiServices/AutomationLibrary/apiCalls';

const SapLibrary = () => {
  const navigate = useNavigate();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [cardsData, setCardsData] = useState([]);
  const [hasNext, setHasNext] = useState(0); // Tracks if more pages are available
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch initial dropdown data and card data
  useEffect(() => {
    async function fetchData() {
      const dropdownData = await fetchDropdownData('api/filter-list?erp=sap');
      setDropdownOptions(

        dropdownData.enterprise_list || [],
      );

      const response = await fetchErpsapCardData(selectedDropdown, 1);
      setCardsData(response?.automation_scenario_list || []);
      setHasNext(response?.has_next || 0);
    }

    fetchData();
  }, []);

  // Fetch cards whenever dropdown values change
  useEffect(() => {
    async function fetchCards() {
      const response = await fetchErpsapCardData(selectedDropdown, 1, searchText);
      setCardsData(response?.automation_scenario_list || []);
      setHasNext(response?.has_next || 0);
      setCurrentPage(1); // Reset to page 1 on filter change
    }

    fetchCards();
  }, [selectedDropdown, searchText]);


  const loadMoreCards = async () => {
    const nextPage = currentPage + 1;
    const response = await fetchErpsapCardData(selectedDropdown, nextPage, searchText);
    setCardsData((prevCards) => [
      ...prevCards,
      ...(response?.automation_scenario_list || []),
    ]);
    setHasNext(response?.has_next || 0);
    setCurrentPage(nextPage);
  };
  const handleCardClick = (slug1, slug2) => {
    navigate(`/automation-library/runmybotforsap/${slug1}/${slug2}`);
  };

  return (
    <div className={styles.SapLibraryContainer}>
      <h1 className={styles.heading}>Connect with <span className='rmb_run'>RUN</span><span className='rmb_my'>MY</span><span className='rmb_bot'>BOT®</span> and see
        powerful results</h1>
      <div className={styles.filters}>
        {/* <select className={styles.dropdown}
            value={selectedDropdown1}
            onChange={(e) => setSelectedDropdown1(e.target.value)}
          >
            <option value="">By Function</option>
            {dropdownOptions.array1.map((item, index) => (
              <option key={index} value={item.id}>
                {item.title}
              </option>
            ))}
          </select> */}
        <select className={styles.dropdown}
          value={selectedDropdown}
          onChange={(e) => setSelectedDropdown(e.target.value)}
        >
          <option value="">By Application</option>
          {dropdownOptions?.map((item, index) => (
            <option key={index} value={item.slug}>
              {item.title}
            </option>
          ))}
        </select>
        <input
          type="text"
          className={styles.search}
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>


      {cardsData?.length > 0 ? (

        <div className={styles.cardGrid}>
          {cardsData.map((card, index) => (
            <div key={index} className={styles.libraryCard} onClick={() => handleCardClick(card.application__slug, card.slug)}>
              <div className={styles.image_container}>
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}attachments/${card.icon}`}
                  alt={card.label}
                  className={styles.cardImage}
                />
              </div>
              <div className={styles.cardLabel}>{card.short_description}</div>
            </div>
          ))}
        </div>

      ) : (
        <div className={styles.noDataFound}>No data found.</div>
      )}

      {hasNext === 1 && (
        <div className={styles.loadMore}>
          <button onClick={loadMoreCards}>Load More
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M16 12L8 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M13 9L16 12L13 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>

      )}

    </div>
  );
}

export default SapLibrary