import React from 'react'
import styles from './footerBottom.module.css'
import gmailLogo from '../../../Assets/Images/gmail.svg'
import facebookLogo from '../../../Assets/Images/facebook.svg'
import LinkedinLogo from '../../../Assets/Images/linkdin.svg'
import XLogo from '../../../Assets/Images/Xlogo.svg'
const FooterBottom = () => {
  return (
    < div className={styles.bottomSection}>


      <div className={styles.footerlinksSection}>

        <div className={styles.copyRight}>
          &copy; 2025 Copyright actionfi. All rights reserved
        </div>
        <div className={styles.footerIcons}>
          <span className={styles.LogoWrapper} > <a href="mailto:hello@actionfi.com" target="_blank" rel="noopener noreferrer">
            <img src={gmailLogo} alt="Logo" className={styles.footer__logoImg} />
          </a> </span>
          <span className={styles.LogoWrapper} > <a href="https://www.facebook.com/actionfi2015" target="_blank" rel="noopener noreferrer"> <img src={facebookLogo} alt="Logo" className={styles.footer__logoImg} /> </a></span>
          <span className={styles.LogoWrapper} >  <a href="https://www.linkedin.com/company/actionfi/" target="_blank" rel="noopener noreferrer"><img src={LinkedinLogo} alt="Logo" className={styles.footer__logoImg} /></a></span>
          <span className={styles.LogoWrapper} > <a href="https://x.com/actionfi_" target="_blank" rel="noopener noreferrer"> <img src={XLogo} alt="Logo" className={styles.footer__logoImg} /></a></span>
        </div>

      </div>
    </div>



  )
}

export default FooterBottom