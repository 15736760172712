

import React from 'react';
import { useEffect, useState } from "react";
import acumatica from '../../../Assets/Images/animationlogos/acumatica.svg'
import blackline from '../../../Assets/Images/animationlogos/blackline.svg'
import reconnet from '../../../Assets/Images/animationlogos/reconnet.svg'
import qu from '../../../Assets/Images/animationlogos/qu.svg'
import cadency from '../../../Assets/Images/animationlogos/cadency.svg'
import sqlserver from '../../../Assets/Images/animationlogos/sqlserver.svg'
import sailpoint from '../../../Assets/Images/animationlogos/sailpoint.svg'
import sharepoint from '../../../Assets/Images/animationlogos/sharepoint.svg'
import gmail from '../../../Assets/Images/animationlogos/gmail.svg'
import developerconsole from '../../../Assets/Images/animationlogos/developerconsole.svg'
import snowflake from '../../../Assets/Images/animationlogos/snowflake.svg'
import sftp from '../../../Assets/Images/animationlogos/sftp.svg'
import swift from '../../../Assets/Images/animationlogos/swift.svg'
import saviynt from '../../../Assets/Images/animationlogos/saviynt.svg'
import dynamics from '../../../Assets/Images/animationlogos/dynamics.svg'
import oracle1 from '../../../Assets/Images/animationlogos/oracle1.svg'
import dynamics2 from '../../../Assets/Images/animationlogos/dynamics2.svg'
import odoo from '../../../Assets/Images/animationlogos/odoo.svg'
import rmb from '../../../Assets/Images/animationlogos/runmybott.svg'
import adra from '../../../Assets/Images/animationlogos/adra.svg'
import oracle2 from '../../../Assets/Images/animationlogos/oracle2.svg'
import oracle3 from '../../../Assets/Images/animationlogos/oracle3.svg'
import oracle4 from '../../../Assets/Images/animationlogos/oracle4.svg'
import bussiness from '../../../Assets/Images/animationlogos/bussiness.svg'
import infor1 from '../../../Assets/Images/animationlogos/infor1.svg'
import netsuite from '../../../Assets/Images/animationlogos/netsuite.svg'
import sap from '../../../Assets/Images/animationlogos/sap.svg'
import dynamics3 from '../../../Assets/Images/animationlogos/dynamics3.svg'
import sap2 from '../../../Assets/Images/animationlogos/sap2.svg'
import qb from '../../../Assets/Images/animationlogos/qb.svg'
import sage1 from '../../../Assets/Images/animationlogos/sage1.svg'
import plaid from '../../../Assets/Images/animationlogos/plaid.svg'
import visma1 from '../../../Assets/Images/animationlogos/visam1.svg'
import xero from '../../../Assets/Images/animationlogos/xero.svg'
import icon from '../../../Assets/Images/animationlogos/icon.svg'
import salesforce from '../../../Assets/Images/animationlogos/salesforce.svg'
import googlecloud from '../../../Assets/Images/animationlogos/googlecloud.svg'
import dynamics4 from '../../../Assets/Images/animationlogos/dynamics4.svg'
import azure from '../../../Assets/Images/animationlogos/MSAzure.svg'
import './spinner.css'; // Import your CSS here

const Spinner = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const containerItem = [
    {
      items1: [
        { id: 1, imgSrc: sap, delay: '-0.0s' },
        { id: 2, imgSrc: sap2, delay: '-2.5s' },
        { id: 3, imgSrc: sftp, delay: '-5.0s' },
        { id: 4, imgSrc: plaid, delay: '-7.5s' },
        { id: 5, imgSrc: visma1, delay: '-10.0s' },
        { id: 6, imgSrc: infor1, delay: '-12.5s' },
        { id: 7, imgSrc: snowflake, delay: '-15.0s' },
        { id: 8, imgSrc: dynamics, delay: '-17.5s' },
        { id: 9, imgSrc: dynamics2, delay: '-20.0s' },
        { id: 10, imgSrc: swift, delay: '-22.5s' },
        { id: 11, imgSrc: cadency, delay: '-25.0s' },
        { id: 12, imgSrc: reconnet, delay: '-27.5s' },
        { id: 13, imgSrc: sqlserver, delay: '-30.0s' },
        { id: 14, imgSrc: saviynt, delay: '-32.5s' },
        { id: 15, imgSrc: netsuite, delay: '-35.0s' },
        { id: 16, imgSrc: qu, delay: '-37.5s' },
        { id: 17, imgSrc: dynamics3, delay: '-40.0s' },
        { id: 18, imgSrc: icon, delay: '-42.5s' }
      ],
      items2: [
        { id: 1, imgSrc: oracle1, delay: '-0.0s' },
        { id: 2, imgSrc: oracle2, delay: '-5.0s' },
        { id: 3, imgSrc: oracle3, delay: '-10.0s' },
        { id: 4, imgSrc: oracle4, delay: '-15.0s' },
        { id: 1, imgSrc: adra, delay: '-20.0s' },
        { id: 2, imgSrc: sailpoint, delay: '-25.0s' },
        { id: 3, imgSrc: acumatica, delay: '-30.0s' },
        { id: 1, imgSrc: developerconsole, delay: '-35.0s' },
        { id: 1, imgSrc: dynamics4, delay: '-40.0s' }
      ],
      items3: [
        { id: 1, imgSrc: sage1, delay: '-0.0s' },
        { id: 2, imgSrc: qb, delay: '-4.5s' },
        { id: 3, imgSrc: xero, delay: '-9.0s' },
        { id: 4, imgSrc: odoo, delay: '-13.5s' },
        { id: 5, imgSrc: azure, delay: '-18.0s' },
        { id: 1, imgSrc: gmail, delay: '-22.5s' },
        { id: 2, imgSrc: sharepoint, delay: '-27.0s' },
        { id: 3, imgSrc: googlecloud, delay: '-31.5s' },
        { id: 4, imgSrc: salesforce, delay: '-36.0s' },
        { id: 1, imgSrc: bussiness, delay: '-40.5s' }
      ]
    }
  ]
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setActiveIndex((prevIndex) => (prevIndex + 1) % containerItem.length);
  //   }, 10000); // Change object every 30 seconds

  //   return () => clearInterval(interval); // Cleanup interval on unmount
  // }, [containerItem.length]);


  return (
    <div id="outer-container">
      {
        containerItem.map((container, containerIndex) => (
          <div
            key={containerIndex}
            className={`container_main ${activeIndex === containerIndex ? "fade-in" : "fade-out"}`}
            style={{
              animationDelay: `${containerIndex * 10}s`, // Offset for each container
            }}
          >
            <div id="container2" >
              {container.items1.map((item, index) => (
                <div
                  key={index}
                  className={`item2 ${activeIndex === containerIndex ? "fade-in" : "fade-out"}`}
                  style={{
                    '--d': item.delay
                  }}>
                  <img className='item_img' src={item.imgSrc} alt={`item-${item.id}`} />

                </div>
              ))}
            </div>
            <div id="container1" >
              {container.items2.map((item, index) => (

                <div
                  key={index}
                  className={`item3 ${activeIndex === containerIndex ? "fade-in" : "fade-out"}`}
                  style={{
                    '--d': item.delay  // Set the delay dynamically based on the item index
                  }} >
                  <img className='item_img' src={item.imgSrc} alt={`item-${item.id}`} />
                </div>



              ))}
            </div>

            <div id="container" >
              {container.items3.map((item, index) => (
                <div
                  key={index}
                  className={`item ${activeIndex === containerIndex ? "fade-in" : "fade-out"}`}
                  style={{
                    '--d': item.delay   // Set the delay dynamically based on the item index
                  }}
                >
                  <img className='item_img' src={item.imgSrc} alt={`item-${item.id}`} />
                </div>
              ))}
            </div>



          </div>))
      }
      <div id="container4">
        <div className='item5'>
          <img className="center_rmbimg" src={rmb} alt={`item-`} />
        </div>
      </div>
    </div>
  );
};

export default Spinner;
