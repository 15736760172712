import React from 'react'
import styles from './actionfiFeatures.module.css'
import built_integration from '../../Assets/Images/WhyActionfi/whyactionfipurpose.svg'
import just_integratoin from '../../Assets/Images/WhyActionfi/whyafiintegrations.svg'
import market_partner from '../../Assets/Images/WhyActionfi/whyafipartnerr.svg'

const ActionfiFeatures = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: "2rem" }}>
      <span className={styles.featuresHeading}>What makes actionfi different?</span>
      <div className={styles.featuresContainer}>
        {/* First Feature */}
        <div className={`${styles.featureSection} ${styles.leftImage}`}>
          <div className={styles.textContent}>
            <div className={styles.featureLabel}>Purpose built integrations</div>
            <p className={styles.featureDescription}>
              We create purpose-built integrations designed specifically to address unique business needs and objectives.
              By developing tailored solutions that seamlessly connect your systems, applications, and data, we ensure that every integration enhances
              efficiency, drives innovation, and supports your strategic goals. Our approach goes beyond generic solutions, focusing on delivering precise,
              targeted integrations that fit perfectly within your existing ecosystem and adapt to your evolving requirements.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <svg
              className={`${styles.circle} ${styles.circleOne}`}
              style={{ animationDelay: '0s' }}

              xmlns="http://www.w3.org/2000/svg"
              width="160"
              height="160"
              viewBox="0 0 160 160"
              fill="none"
            >
              <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <circle cx="80" cy="80" r="80" fill="#4F63BE" fillOpacity="0.7" />
              </g>
            </svg>

            {/* Image */}
            <img src={built_integration} alt="Purpose built integrations" className={styles.featureImage} />

            {/* Second Circle */}
            <svg
              className={`${styles.circle} ${styles.circleTwo}`}
              style={{ animationDelay: '0s' }}

              xmlns="http://www.w3.org/2000/svg"
              width="201"
              height="201"
              viewBox="0 0 201 201"
              fill="none"
            >
              <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <circle cx="100.5" cy="100.5" r="100.5" fill="#F4D7F5" fillOpacity="0.7" />
              </g>
            </svg>

          </div>
        </div>

        {/* Second Feature */}
        <div className={`${styles.featureSection} ${styles.rightImage}`}>
          <div className={styles.textContent}>
            <div className={styles.featureLabel}>More than just integrations</div>
            <p className={styles.featureDescription}>
              We don’t just handle integrations; our platform also features advanced scheduler, robust transformation capabilities,
              and a powerful calculation engine. This comprehensive suite of tools ensures seamless data flow, efficient processing,
              and precise analytical functions, all tailored to enhance your operational efficiency and support complex business processes.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <svg
              className={`${styles.circle} ${styles.circleOne2}`}
              style={{ animationDelay: '0s' }}

              xmlns="http://www.w3.org/2000/svg"
              width="160"
              height="160"
              viewBox="0 0 160 160"
              fill="none"
            >
              <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <circle cx="80" cy="80" r="80" fill="#4F63BE" fillOpacity="0.7" />
              </g>
            </svg>
            <img src={just_integratoin} alt="More than just integrations" className={styles.featureImage} />
            <svg
              className={`${styles.circle} ${styles.circleTwo2}`}
              style={{ animationDelay: '0s' }}

              xmlns="http://www.w3.org/2000/svg"
              width="201"
              height="201"
              viewBox="0 0 201 201"
              fill="none"
            >
              <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <circle cx="100.5" cy="100.5" r="100.5" fill="#F4D7F5" fillOpacity="0.7" />
              </g>
            </svg>
            <svg className={styles.rectangle} width="588" height="138" viewBox="0 0 588 138" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="588" height="138" rx="40" fill="#F6FAFF" />
            </svg>

          </div>
        </div>

        {/* Third Feature */}
        <div className={`${styles.featureSection} ${styles.leftImage}`} >
          <div className={styles.textContent}>
            <div className={styles.featureLabel}>Go to Market partner</div>
            <p className={styles.featureDescription}>
              Our commitment extends beyond creating integrations and automations. We view our customers as partners and offer comprehensive go-to-market support.
              This includes providing valuable assets, sharing expertise, delivering 24/7 customer support, and participating in meetings.
              We are dedicated to growing alongside our partners and ensuring their success.
            </p>
          </div>
          <div className={styles.imageContainer} >
            <svg xmlns="http://www.w3.org/2000/svg" className={`${styles.circle} ${styles.circleOne3}`}
              style={{ animationDelay: '0s' }} width="201" height="201" viewBox="0 0 201 201" fill="none">
              <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <circle cx="100.5" cy="100.5" r="100.5" fill="#4F63BE" fill-opacity="0.7" />
              </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="156" height="156" viewBox="0 0 156 156" fill="none" className={`${styles.circle} ${styles.circleTwo3}`}
              style={{ animationDelay: '0s' }}>
              <g style={{ mixBlendMode: "multiply" }} opacity="0.8" >
                <circle cx="78" cy="78" r="78" fill="#F4D7F5" fill-opacity="0.7" />
              </g>
            </svg>
            <img src={market_partner} alt="Go to Market partner" className={styles.featureImage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActionfiFeatures;
