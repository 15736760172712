import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import styles from './navbar.module.css'; // Import styles for the navbar
import actionFiLogo from "../../../Assets/Images/actionfiLogo.png";
import CustomDropdownBar from "../../CustomNavdropdown/CustomDropdownBar";
import { fetchFunctionsMenuDetails, fetchIntegrationMenuDetails, fetchSystemMenuDetails, fetchWorkflowmMenuDetails } from "../../../apiServices/Menubar/apiCalls";
// Dynamic data for eac
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(""); // Tracks the active dropdown section
  const [dropdownData, setDropdownData] = useState({
    solutions: [
      {
        title: "By Integrations",
        path: "connect",
        items: [

        ],
      },
      // {
      //   title: "By Functions",
      //   path: "functions/",
      //   items: [

      //   ],
      // },
      {
        title: "By Workflows",
        path: "workflows",
        items: [

        ],
      },
      {
        title: "By Systems",
        path: "systems",
        items: [

        ],
      },
    ],
    platform: [

      {
        title: "Connect",
        path: "connect",

      },
      {
        title: "Data Wrangler",
        path: "datawrangler",

      },
      // {
      //   title: "Workflow Builder",
      //   path:"workflowbuilder",

      // },
      // {
      //   title: "Analyze",
      //   path:"ai-analyze",

      // },

      // {
      //   title: "Automation Library",
      //   path: "automationlibrary",

      // },
      // {
      //   title: "Event Pro",
      //   path:"runmybot-eventpro",

      // },
      {
        title: "RUNMYBOT for SAP",
        path: "runmybotforsap",

      },
    ],
  })
  const navigate = useNavigate();
  const divRef = useRef(null);

  useEffect(() => {
    // Fetch all API data concurrently
    const fetchData = async () => {
      try {
        const [integrationData, functionData, workflowData, systemData] = await Promise.all([
          fetchIntegrationMenuDetails(),
          fetchFunctionsMenuDetails(),
          fetchWorkflowmMenuDetails(),
          fetchSystemMenuDetails(),
        ]);

        // Map responses to the corresponding dropdown solution
        setDropdownData((prevData) => ({
          ...prevData,
          solutions: prevData.solutions.map((solution) => {
            let updatedItems = [];
            if (solution.title === "By Integrations") updatedItems = integrationData.integrations;
            if (solution.title === "By Functions") updatedItems = functionData.functions;
            if (solution.title === "By Workflows") updatedItems = workflowData.workflows;
            if (solution.title === "By Systems") updatedItems = systemData.systems;

            return {
              ...solution,
              items: updatedItems,
            };
          }),
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };
  const handleMouseEnter = (dropdownKey) => {
    setActiveDropdown(dropdownKey); // Open the dropdown on hover
  };

  const handleMouseLeave = () => {
    setActiveDropdown(""); // Close the dropdown when the mouse leaves
  };


  // Function to handle clicks outside the div
  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    // Attach event listener to the document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Close the menu when resizing above 1000px
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1120) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbar__logo}>
        <Link to="/">
          <img src={actionFiLogo} alt="Logo" className={styles.navbar__logoImg} />
        </Link>
      </div>
      <button className={styles.hamburger} onClick={toggleMenu}>
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#4F63BE"
        >
          <path
            d="M3 6.00092H21M3 12.0009H21M3 18.0009H21"
            stroke="#292929"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </button>
      <ul ref={divRef} className={`${styles.navbar__links} ${isMenuOpen ? styles.navbar__links__visible : ""
        }`}>
        {isMenuOpen && (
          <span className={styles.close} onClick={() => setIsMenuOpen(false)}>
            <svg width="40" height="40" viewbox="0 0 40 40"><path d="M 10,10 L 30,30 M 30,10 L 10,30" stroke="black" stroke-width="4" /></svg>
          </span>
        )}
        <li
          className={styles.navbar__item}
          onMouseEnter={() => handleMouseEnter("solutions")}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink
            to="/solutions"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`

            }

          >
            Solutions
          </NavLink>
          {activeDropdown === "solutions" && (
            <div
              className={`${styles.dropdownWrapper} ${styles.solution_position}`}
              onClick={(e) => e.stopPropagation()}
            >
              <CustomDropdownBar
                dropdownData={dropdownData.solutions}
                setIsMenuOpen={() => setIsMenuOpen(false)}
                handleNavigation={(path) => {

                  setActiveDropdown("");
                  setIsMenuOpen(false)
                  navigate(path);
                }}
              />
            </div>
          )}
        </li>
        <li
          className={styles.navbar__item}
          onMouseEnter={() => handleMouseEnter("platform")}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink
            to="/platform"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`
            }
          >
            Platform
          </NavLink>
          {activeDropdown === "platform" && (
            <div
              className={`${styles.dropdownWrapper} ${styles.platform_position}`}
              onClick={(e) => e.stopPropagation()}
            >
              <CustomDropdownBar
                dropdownData={dropdownData.platform}
                handleNavigation={(path) => {
                  setActiveDropdown("");
                  setIsMenuOpen(false)
                  navigate(path);
                }}
              />
            </div>
          )}
        </li>
        <li className={styles.navbar__item}>
          <NavLink
            to="/whyactionfi"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`
            }
            onClick={(e) => setIsMenuOpen(false)}
          >
            Why actionfi?
          </NavLink>
        </li>
        <li className={styles.navbar__item}>
          <NavLink
            to="/company"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`
            }
            onClick={(e) => setIsMenuOpen(false)}
          >
            Company
          </NavLink>
        </li>
        <li className={styles.navbar__item}>
          <NavLink
            to="/partners"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`
            }
            onClick={(e) => setIsMenuOpen(false)}
          >
            Partners
          </NavLink>
        </li>
        <li className={styles.navbar__item}>
          <NavLink
            to="/contactus"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`
            }
            onClick={(e) => setIsMenuOpen(false)}
          >
            Contact Us
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
